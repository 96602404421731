import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  JSONObject: any;
  Upload: any;
};

export type AbuseAiInput = {
  commuId: Scalars['Float'];
  id: Scalars['Float'];
};

export type AbuseReplyAiInput = {
  id: Scalars['Float'];
  replyId: Scalars['Float'];
};

export type AddBlackListEmailInput = {
  email: Scalars['String'];
};

export type AddCommuNoticeInput = {
  bedongNoticeTypeId?: InputMaybe<Scalars['Float']>;
  body?: InputMaybe<Scalars['String']>;
  commuDt?: InputMaybe<Scalars['String']>;
  hide: Scalars['Float'];
  isImageType: Scalars['String'];
  selectedGroupIdList?: InputMaybe<Array<Scalars['Float']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type AdminCreateInput = {
  email: Scalars['String'];
  isCommunityPermit: Scalars['Boolean'];
  password: Scalars['String'];
  role: Scalars['Float'];
  username: Scalars['String'];
};

export type AdminPasswordChangeInput = {
  id: Scalars['Float'];
  password: Scalars['String'];
};

export type AdminsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type AdminsResponse = {
  __typename?: 'AdminsResponse';
  count: Scalars['Float'];
  data: Array<Users>;
};

export type AppContents = {
  __typename?: 'AppContents';
  content?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  language: Scalars['Int'];
  name: Scalars['String'];
  requiredVersion?: Maybe<Scalars['String']>;
  screen?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type AppContentsCreateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  language?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  requiredVersion?: InputMaybe<Scalars['String']>;
  screen?: InputMaybe<Scalars['String']>;
  /** 타입으로 구분시 옵션으로 사용, 1: 초기 컨텐츠, 2: 팝업, 3: 푸시 */
  type?: InputMaybe<Scalars['Float']>;
};

export type AppContentsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type AppContentsResponse = {
  __typename?: 'AppContentsResponse';
  count: Scalars['Float'];
  data: Array<AppContents>;
};

export type AppContentsUpdateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  id: Scalars['Float'];
  language?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  requiredVersion?: InputMaybe<Scalars['String']>;
  screen?: InputMaybe<Scalars['String']>;
  /** 타입으로 구분시 옵션으로 사용, 1: 초기 컨텐츠, 2: 팝업, 3: 푸시 */
  type?: InputMaybe<Scalars['Float']>;
};

export type AppSplash = {
  __typename?: 'AppSplash';
  accessPath: Scalars['String'];
  assumedName: Scalars['String'];
  createdAt: Scalars['Date'];
  end?: Maybe<Scalars['Date']>;
  extension: Scalars['String'];
  id: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  language: Scalars['String'];
  length: Scalars['Float'];
  originalName: Scalars['String'];
  period: Period;
  start?: Maybe<Scalars['Date']>;
  type: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type AppVersionCreateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  /** 1-한국어, 2-베트남, 3-영어, 4-태국어, 5-인도네시아 */
  locale?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  /** 1-INIT_CONTENTS, 2-POPUP, 3-PUSH, 4-AppVersion */
  type: Scalars['Float'];
};

export type AppVersionUpdateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type AppVersionsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type AppVersionsResponse = {
  __typename?: 'AppVersionsResponse';
  count: Scalars['Float'];
  data: Array<AppContents>;
};

export type AuthorCreateInput = {
  about?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: InputMaybe<Scalars['String']>;
};

/** aa */
export type Authors = {
  __typename?: 'Authors';
  about?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<Posts>>;
};

export type AuthorsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type AuthorsResponse = {
  __typename?: 'AuthorsResponse';
  count: Scalars['Float'];
  data: Array<Authors>;
};

export type Baby = {
  __typename?: 'Baby';
  afterBabyname?: Maybe<Scalars['String']>;
  afterBirthday?: Maybe<Scalars['Date']>;
  babyGender?: Maybe<Scalars['Int']>;
  babyname?: Maybe<Scalars['String']>;
  bedongGroup?: Maybe<BedongGroup>;
  commuDt?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  duedate?: Maybe<Scalars['Date']>;
  familyId: Scalars['Int'];
  familyMember?: Maybe<FamilyMember>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  laborAt?: Maybe<Scalars['Date']>;
  multipleBirths?: Maybe<Scalars['Int']>;
  pregnantStatus: Scalars['Int'];
};

export type BedongBlockUserInput = {
  id: Scalars['Float'];
  value: Scalars['Float'];
};

export type BedongGroup = {
  __typename?: 'BedongGroup';
  babys?: Maybe<Array<Baby>>;
  bedongNoticeGroup?: Maybe<Array<BedongNoticeGroup>>;
  bedongPosts?: Maybe<Array<Commus>>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  locale: Scalars['Int'];
  month?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  year?: Maybe<Scalars['Int']>;
};

export type BedongNoticeGroup = {
  __typename?: 'BedongNoticeGroup';
  bedongGroup?: Maybe<BedongGroup>;
  bedongGroupId: Scalars['Int'];
  bedongNotice?: Maybe<CommuNotices>;
  bedongNoticeId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt: Scalars['Date'];
  id: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type BedongNoticeGroupResponse = {
  __typename?: 'BedongNoticeGroupResponse';
  data: Array<BedongGroup>;
};

export type BedongNoticeType = {
  __typename?: 'BedongNoticeType';
  color: Scalars['String'];
  id: Scalars['Int'];
  locale: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type BedongNoticeTypeResponse = {
  __typename?: 'BedongNoticeTypeResponse';
  data: Array<BedongNoticeType>;
};

export type BedongReport = {
  __typename?: 'BedongReport';
  bedongNoticeComment?: Maybe<CommuNoticeSub>;
  bedongPost?: Maybe<Commus>;
  bedongPostComment?: Maybe<CommusSub>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<Users>;
};

export type BedongReportResponse = {
  __typename?: 'BedongReportResponse';
  count: Scalars['Float'];
  data: Array<BedongReport>;
};

export type BedongReportsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type BillyPush = {
  __typename?: 'BillyPush';
  body: Scalars['String'];
  createdAt: Scalars['Date'];
  cronExpression?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  image?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isSchedule: Scalars['Boolean'];
  pushData?: Maybe<Scalars['String']>;
  pushTarget?: Maybe<Scalars['String']>;
  reservationDate?: Maybe<Scalars['String']>;
  reservationDay?: Maybe<Array<Scalars['Int']>>;
  reservationTime?: Maybe<Scalars['String']>;
  reservationType?: Maybe<Scalars['String']>;
  reservationWeek?: Maybe<Scalars['Float']>;
  sendCount?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  totalSendCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
};

export type BillyPushCountsResponse = {
  __typename?: 'BillyPushCountsResponse';
  count: Scalars['Float'];
};

export type BoardCreateInput = {
  content?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['Float']>;
  photo?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  /** 1: 이벤트 / 2: 공지 */
  type?: InputMaybe<Scalars['Float']>;
};

export type BoardInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type BoardReactions = {
  __typename?: 'BoardReactions';
  board?: Maybe<Boards>;
  boardId: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  read: Scalars['Boolean'];
  updatedAt: Scalars['Date'];
  userId: Scalars['String'];
};

export type BoardUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  locale?: InputMaybe<Scalars['Float']>;
  photo?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  /** 1: 이벤트 / 2: 공지 */
  type?: InputMaybe<Scalars['Float']>;
};

export type Boards = {
  __typename?: 'Boards';
  boardReactions?: Maybe<Array<BoardReactions>>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  locale?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  publish: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt: Scalars['String'];
  viewcnt?: Maybe<Scalars['Int']>;
};

export type BoardsResponse = {
  __typename?: 'BoardsResponse';
  count: Scalars['Float'];
  data: Array<Boards>;
};

export type BookmarkPost = {
  __typename?: 'BookmarkPost';
  bookmark_id: Scalars['Int'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  post: Posts;
  post_id: Scalars['Int'];
  updatedAt: Scalars['Date'];
  user: Users;
  userId?: Maybe<Scalars['Int']>;
};

export type CommonIdInput = {
  id: Scalars['Float'];
};

export type CommonSuccess = {
  __typename?: 'CommonSuccess';
  success: Scalars['Boolean'];
};

export type CommuAbuseInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type CommuAbuseResponse = {
  __typename?: 'CommuAbuseResponse';
  count: Scalars['Float'];
  data: Array<CommusAbuseEntity>;
};

export type CommuNoticeSub = {
  __typename?: 'CommuNoticeSub';
  bedongReport?: Maybe<BedongReport>;
  body: Scalars['String'];
  commuId?: Maybe<Scalars['Int']>;
  commuNotice?: Maybe<CommuNotices>;
  createdAt?: Maybe<Scalars['Date']>;
  hide: Scalars['Int'];
  id: Scalars['Int'];
  likeCount?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  replySort?: Maybe<Scalars['Int']>;
  subId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
  users?: Maybe<Users>;
};

export type CommuNotices = {
  __typename?: 'CommuNotices';
  bedongNoticeGroup?: Maybe<Array<BedongNoticeGroup>>;
  bedongNoticeType?: Maybe<BedongNoticeType>;
  body?: Maybe<Scalars['String']>;
  commuDt?: Maybe<Scalars['String']>;
  commuNoticeReply?: Maybe<Array<CommuNoticeSub>>;
  createdAt?: Maybe<Scalars['Date']>;
  endDt?: Maybe<Scalars['Date']>;
  hide: Scalars['Int'];
  id: Scalars['Int'];
  kind: Scalars['Int'];
  likeCount?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  reactions?: Maybe<Scalars['Int']>;
  startDt?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
  users?: Maybe<Users>;
};

export type CommuReplyAbuseInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type CommuReplyAbuseResponse = {
  __typename?: 'CommuReplyAbuseResponse';
  count: Scalars['Float'];
  data: Array<CommusReplyAbuseEntity>;
};

export type CommuReplyInput = {
  body: Scalars['String'];
  commuId: Scalars['Float'];
  nestedReply?: InputMaybe<Scalars['Float']>;
};

export type CommuReports = {
  __typename?: 'CommuReports';
  commuOrSubId: Scalars['Int'];
  commus?: Maybe<Commus>;
  commusSub?: Maybe<CommusSub>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  ipaddr: Scalars['String'];
  kind: Scalars['Int'];
  reportInfo1?: Maybe<Scalars['String']>;
  reportInfo2?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
  users?: Maybe<Users>;
};

export type CommuReportsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type CommuReportsResponse = {
  __typename?: 'CommuReportsResponse';
  count: Scalars['Float'];
  data: Array<CommuReports>;
};

export type CommuUserBlacklist = {
  __typename?: 'CommuUserBlacklist';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  ipaddr: Scalars['String'];
  months?: Maybe<Scalars['String']>;
  selfie?: Maybe<Scalars['String']>;
  target?: Maybe<Users>;
  targetId: Scalars['Int'];
  updatedAt: Scalars['Date'];
  user?: Maybe<Users>;
  userId: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type CommuUserBlockRepostInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type CommuUserBlocksResponse = {
  __typename?: 'CommuUserBlocksResponse';
  count: Scalars['Float'];
  data: Array<CommuUserBlacklist>;
};

export type CommuUserReports = {
  __typename?: 'CommuUserReports';
  commuId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  ipaddr: Scalars['String'];
  reportInfo1?: Maybe<Scalars['String']>;
  reportInfo2?: Maybe<Scalars['String']>;
  target?: Maybe<Users>;
  targetId: Scalars['Int'];
  updatedAt: Scalars['Date'];
  user?: Maybe<Users>;
  userId: Scalars['Int'];
};

export type CommuUserReportsResponse = {
  __typename?: 'CommuUserReportsResponse';
  count: Scalars['Float'];
  data: Array<CommuUserReports>;
};

export type Commus = {
  __typename?: 'Commus';
  adminsub?: Maybe<Array<CommusSub>>;
  bedongGroup?: Maybe<BedongGroup>;
  bedongGroupId?: Maybe<Scalars['Int']>;
  bedongReport?: Maybe<BedongReport>;
  body?: Maybe<Scalars['String']>;
  commuDt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  headerId?: Maybe<Scalars['Int']>;
  hide: Scalars['Int'];
  id: Scalars['Int'];
  ipaddr: Scalars['String'];
  likeCount?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['Int']>;
  monthtag?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  reactions?: Maybe<Scalars['Int']>;
  replyCount?: Maybe<Scalars['Int']>;
  reports?: Maybe<Array<CommuReports>>;
  reportsCount?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
  users?: Maybe<Users>;
};

export type CommusAbuseEntity = {
  __typename?: 'CommusAbuseEntity';
  body?: Maybe<Scalars['String']>;
  commuId: Scalars['Int'];
  commus?: Maybe<Commus>;
  createdAt: Scalars['Date'];
  hide?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type CommusHideInput = {
  hide: Scalars['Float'];
  id: Scalars['Float'];
  type: Scalars['String'];
};

export type CommusInput = {
  bedongGroupId?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['String']>;
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  hotContents?: InputMaybe<Scalars['Float']>;
  openType?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type CommusNoticeInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type CommusNoticeResponse = {
  __typename?: 'CommusNoticeResponse';
  count: Scalars['Float'];
  data: Array<CommuNotices>;
};

export type CommusReplyAbuseEntity = {
  __typename?: 'CommusReplyAbuseEntity';
  body?: Maybe<Scalars['String']>;
  commusReply?: Maybe<CommusSub>;
  createdAt: Scalars['Date'];
  hide?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  replyId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type CommusResponse = {
  __typename?: 'CommusResponse';
  count: Scalars['Float'];
  data: Array<Commus>;
};

export type CommusSub = {
  __typename?: 'CommusSub';
  bedongReport?: Maybe<BedongReport>;
  body?: Maybe<Scalars['String']>;
  commuId?: Maybe<Scalars['Int']>;
  commus?: Maybe<Commus>;
  createdAt?: Maybe<Scalars['Date']>;
  hide: Scalars['Int'];
  id: Scalars['Int'];
  ipaddr: Scalars['String'];
  likeCount?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  replySort?: Maybe<Scalars['Int']>;
  reports?: Maybe<Array<CommuReports>>;
  subId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
  users?: Maybe<Users>;
};

export type CouponInfo = {
  couponCd: Scalars['String'];
  quantity: Scalars['Float'];
};

export type CouponResponseInfo = {
  __typename?: 'CouponResponseInfo';
  couponCd: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CreateMarketingEventInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  eventName: Scalars['String'];
  eventType: Scalars['String'];
  exposedTargetGroup: Array<Scalars['String']>;
  exposedTargetScreen: Array<Scalars['String']>;
  landingScreenUrl: Scalars['String'];
  popupContentFirst?: InputMaybe<Scalars['String']>;
  popupContentSecond?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};

export type CreatePostInput = {
  author: Scalars['Float'];
  content?: InputMaybe<Scalars['String']>;
  portal?: InputMaybe<Scalars['Float']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<ITags>>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateSplasheInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  isDefault: Scalars['Boolean'];
  language: Scalars['String'];
  originalName: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type DeleteBlackListEmailInput = {
  id: Scalars['Float'];
};

export type DeleteCommuInput = {
  commuId: Scalars['Float'];
};

export type DeleteCommuReplyInput = {
  commuId: Scalars['Float'];
  replyId: Scalars['Float'];
};

export type EditCommuInput = {
  body?: InputMaybe<Scalars['String']>;
  commuId: Scalars['Float'];
  isImageType: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type EditCommuNoticeInput = {
  bedongNoticeTypeId?: InputMaybe<Scalars['Float']>;
  body?: InputMaybe<Scalars['String']>;
  commuDt?: InputMaybe<Scalars['String']>;
  commuId: Scalars['Float'];
  hide: Scalars['Float'];
  isImageType: Scalars['String'];
  selectedGroupIdList?: InputMaybe<Array<Scalars['Float']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditCommuReplyInput = {
  body?: InputMaybe<Scalars['String']>;
  commuId: Scalars['Float'];
  isImageType?: InputMaybe<Scalars['String']>;
  replyId: Scalars['Float'];
};

export type EditorS3Respone = {
  __typename?: 'EditorS3Respone';
  link: Scalars['String'];
};

export type EventInput = {
  content?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
  /** 0: 0원이벤트 */
  type?: InputMaybe<Scalars['Float']>;
};

export type EventUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Float'];
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
  /** 0: 0원이벤트 */
  type?: InputMaybe<Scalars['Float']>;
};

export type Events = {
  __typename?: 'Events';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  end: Scalars['Date'];
  id: Scalars['Int'];
  locale?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  start: Scalars['Date'];
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type EventsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type EventsResponse = {
  __typename?: 'EventsResponse';
  count: Scalars['Float'];
  data: Array<Events>;
};

export type ExcelResponse = {
  __typename?: 'ExcelResponse';
  buffer: Scalars['String'];
};

export type FamilyMember = {
  __typename?: 'FamilyMember';
  baby?: Maybe<Array<Baby>>;
  createdAt: Scalars['Date'];
  familyId: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  relationType: Scalars['Int'];
  user?: Maybe<Users>;
  userAuthorize: Scalars['Int'];
  userForename: Scalars['String'];
  userId: Scalars['Int'];
};

export type FirebaseTokens = {
  __typename?: 'FirebaseTokens';
  fcmToken?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};

export type GetAppContentByIdRes = {
  __typename?: 'GetAppContentByIdRes';
  data: AppContents;
};

export type GetBoardByIdRes = {
  __typename?: 'GetBoardByIdRes';
  data: Boards;
};

export type GetEventByIdRes = {
  __typename?: 'GetEventByIdRes';
  data: Events;
};

export type GetMarketingEventInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type GetPushByIdResponse = {
  __typename?: 'GetPushByIdResponse';
  data: BillyPush;
};

export type GetPushOpenedCountResponse = {
  __typename?: 'GetPushOpenedCountResponse';
  count?: Maybe<Scalars['Float']>;
};

export type GetRandomCouponByIdRes = {
  __typename?: 'GetRandomCouponByIdRes';
  data: RandomCouponEvents;
};

export type GetSplashesByIdRes = {
  __typename?: 'GetSplashesByIdRes';
  data: AppSplash;
};

export type IPost = {
  id: Scalars['Float'];
};

export type ITags = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type ImageUploadResponseDto = {
  __typename?: 'ImageUploadResponseDto';
  encoding: Scalars['String'];
  fieldName: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  uploadImageLink: Scalars['String'];
};

export type InputLabelWithValue = {
  __typename?: 'InputLabelWithValue';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type LabelValueResponseDto = {
  __typename?: 'LabelValueResponseDto';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: Users;
};

export type MarketingEventImage = {
  __typename?: 'MarketingEventImage';
  createdAt: Scalars['Date'];
  eventId: Scalars['Int'];
  extension: Scalars['String'];
  id: Scalars['Int'];
  originalName: Scalars['String'];
  resourceAccessPath: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type MarketingEventInputLabelResponse = {
  __typename?: 'MarketingEventInputLabelResponse';
  eventType: Array<InputLabelWithValue>;
  exposedScreen: Array<InputLabelWithValue>;
  exposedTargetGroup: Array<InputLabelWithValue>;
};

export type MarketingEventResponse = {
  __typename?: 'MarketingEventResponse';
  count: Scalars['Float'];
  data: Array<MarketingEventResponseDto>;
};

export type MarketingEventResponseDto = {
  __typename?: 'MarketingEventResponseDto';
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  dialogContent?: Maybe<Scalars['String']>;
  dialogTitle?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
  eventName: Scalars['String'];
  eventType: Scalars['String'];
  exposedScreen: Array<LabelValueResponseDto>;
  exposedTargetGroup?: Maybe<Array<LabelValueResponseDto>>;
  id: Scalars['Int'];
  landingScreenUrl?: Maybe<Scalars['String']>;
  languageCode: Scalars['String'];
  marketingEventImages: Array<MarketingEventImage>;
  period: Period;
  probability: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBlackListEmail: CommonSuccess;
  adminCreate: CommonSuccess;
  author: Authors;
  bedongBlockUser: CommonSuccess;
  changeAdminPassword: CommonSuccess;
  changePassword: CommonSuccess;
  commuAiAbusesToggle: CommonSuccess;
  commuHide: CommonSuccess;
  commuNoticeReply: CommonSuccess;
  commuReply: CommonSuccess;
  commuReplyAiAbusesToggle: CommonSuccess;
  createAppContent: CommonSuccess;
  createAppVersion: CommonSuccess;
  createBoard: CommonSuccess;
  createEvent: CommonSuccess;
  createMarketingEvent: CommonSuccess;
  createPost: CommonSuccess;
  createPush: CommonSuccess;
  createRandomCoupon: CommonSuccess;
  createSplash: CommonSuccess;
  deleteAppContent: CommonSuccess;
  deleteAppVersion: CommonSuccess;
  deleteAuthor: CommonSuccess;
  deleteBlackListEmail: CommonSuccess;
  deleteBoard: CommonSuccess;
  deleteCommu: CommonSuccess;
  deleteEvent: CommonSuccess;
  deleteMarketingEvent: CommonSuccess;
  deleteNotice: CommonSuccess;
  deletePost: CommonSuccess;
  deletePostTag: CommonSuccess;
  deletePush: CommonSuccess;
  deleteRandomCoupon: CommonSuccess;
  deleteReplyCommu: CommonSuccess;
  deleteReplyCommuNotice: CommonSuccess;
  deleteSplash: CommonSuccess;
  deleteUser: CommonSuccess;
  editAppContent: CommonSuccess;
  editCommu: CommonSuccess;
  editNotice: CommonSuccess;
  editReplyCommu: CommonSuccess;
  editReplyCommuNotice: CommonSuccess;
  editorDeleteToS3: EditorS3Respone;
  editorUploadToS3: EditorS3Respone;
  hideAllSeasonalTag: CommonSuccess;
  hideSeasonalTag: CommonSuccess;
  imageUpload: Array<ImageUploadResponseDto>;
  login: LoginResponse;
  mobileAuth: CommonSuccess;
  notice: CommonSuccess;
  register: LoginResponse;
  showAllSeasonalTag: CommonSuccess;
  showSeasonalTag: CommonSuccess;
  testPush: CommonSuccess;
  updateAdmin: CommonSuccess;
  updateAppVersion: CommonSuccess;
  updateBoard: CommonSuccess;
  updateEvent: CommonSuccess;
  updateMarketingEvent: CommonSuccess;
  updatePost: CommonSuccess;
  updatePush: CommonSuccess;
  updateRandomCoupon: CommonSuccess;
  updateSplash: CommonSuccess;
};


export type MutationAddBlackListEmailArgs = {
  input: AddBlackListEmailInput;
};


export type MutationAdminCreateArgs = {
  input: AdminCreateInput;
};


export type MutationAuthorArgs = {
  authorCreateInput: AuthorCreateInput;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  froalaFiles?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationBedongBlockUserArgs = {
  input: BedongBlockUserInput;
};


export type MutationChangeAdminPasswordArgs = {
  input: AdminPasswordChangeInput;
};


export type MutationChangePasswordArgs = {
  input: PasswordChangeInput;
};


export type MutationCommuAiAbusesToggleArgs = {
  input: AbuseAiInput;
};


export type MutationCommuHideArgs = {
  input: CommusHideInput;
};


export type MutationCommuNoticeReplyArgs = {
  input: CommuReplyInput;
};


export type MutationCommuReplyArgs = {
  input: CommuReplyInput;
};


export type MutationCommuReplyAiAbusesToggleArgs = {
  input: AbuseReplyAiInput;
};


export type MutationCreateAppContentArgs = {
  appContentCreateInput: AppContentsCreateInput;
};


export type MutationCreateAppVersionArgs = {
  createInput: AppVersionCreateInput;
};


export type MutationCreateBoardArgs = {
  boardCreateInput: BoardCreateInput;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreateEventArgs = {
  froalaImages?: InputMaybe<Array<Scalars['Upload']>>;
  input: EventInput;
};


export type MutationCreateMarketingEventArgs = {
  createInput: CreateMarketingEventInput;
  files?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreatePostArgs = {
  createInput: CreatePostInput;
  files?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreatePushArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: PushInput;
};


export type MutationCreateRandomCouponArgs = {
  input: RandomCouponInput;
};


export type MutationCreateSplashArgs = {
  createSplashInput: CreateSplasheInput;
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationDeleteAppContentArgs = {
  appContentDeleteInput: CommonIdInput;
};


export type MutationDeleteAppVersionArgs = {
  deleteInput: CommonIdInput;
};


export type MutationDeleteAuthorArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteBlackListEmailArgs = {
  input: DeleteBlackListEmailInput;
};


export type MutationDeleteBoardArgs = {
  boardDeleteInput: CommonIdInput;
};


export type MutationDeleteCommuArgs = {
  input: DeleteCommuInput;
};


export type MutationDeleteEventArgs = {
  input: CommonIdInput;
};


export type MutationDeleteMarketingEventArgs = {
  marketingEventDeleteInput: CommonIdInput;
};


export type MutationDeleteNoticeArgs = {
  input: DeleteCommuInput;
};


export type MutationDeletePostArgs = {
  input: CommonIdInput;
};


export type MutationDeletePostTagArgs = {
  input: PostTagInput;
};


export type MutationDeletePushArgs = {
  input: CommonIdInput;
};


export type MutationDeleteRandomCouponArgs = {
  input: CommonIdInput;
};


export type MutationDeleteReplyCommuArgs = {
  input: DeleteCommuReplyInput;
};


export type MutationDeleteReplyCommuNoticeArgs = {
  input: DeleteCommuReplyInput;
};


export type MutationDeleteSplashArgs = {
  splashDeleteInput: CommonIdInput;
};


export type MutationDeleteUserArgs = {
  input: CommonIdInput;
};


export type MutationEditAppContentArgs = {
  appContentUpdateInput: AppContentsUpdateInput;
};


export type MutationEditCommuArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  input: EditCommuInput;
};


export type MutationEditNoticeArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']>>;
  input: EditCommuNoticeInput;
};


export type MutationEditReplyCommuArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  input: EditCommuReplyInput;
};


export type MutationEditReplyCommuNoticeArgs = {
  input: EditCommuReplyInput;
};


export type MutationEditorDeleteToS3Args = {
  uploadS3Input: UploadS3Input;
};


export type MutationEditorUploadToS3Args = {
  file?: InputMaybe<Scalars['Upload']>;
  uploadS3Input: UploadS3Input;
};


export type MutationHideAllSeasonalTagArgs = {
  input: PostArrayInput;
};


export type MutationHideSeasonalTagArgs = {
  input: CommonIdInput;
};


export type MutationImageUploadArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationMobileAuthArgs = {
  id: Scalars['Float'];
  mobileNumber: Scalars['String'];
  type: Scalars['Float'];
};


export type MutationNoticeArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']>>;
  input: AddCommuNoticeInput;
};


export type MutationRegisterArgs = {
  registerInput: RegisterInput;
};


export type MutationShowAllSeasonalTagArgs = {
  input: PostArrayInput;
};


export type MutationShowSeasonalTagArgs = {
  input: CommonIdInput;
};


export type MutationTestPushArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: PushInput;
};


export type MutationUpdateAdminArgs = {
  updateInput: UpdateInput;
};


export type MutationUpdateAppVersionArgs = {
  updateInput: AppVersionUpdateInput;
};


export type MutationUpdateBoardArgs = {
  boardUpdateInput: BoardUpdateInput;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateEventArgs = {
  froalaImages?: InputMaybe<Array<Scalars['Upload']>>;
  input: EventUpdateInput;
};


export type MutationUpdateMarketingEventArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  updateInput: UpdateMarketingEventInput;
};


export type MutationUpdatePostArgs = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  updatePostInput: UpdatePostInput;
};


export type MutationUpdatePushArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  input: PushUpdate;
};


export type MutationUpdateRandomCouponArgs = {
  input: RandomCouponUpdateInput;
};


export type MutationUpdateSplashArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  updateInput: UpdateSplasheInput;
};

export type PasswordChangeInput = {
  id: Scalars['Float'];
  password: Scalars['String'];
};

export type Period = {
  __typename?: 'Period';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type PostArrayInput = {
  postsId: Array<IPost>;
};

export type PostByIdRes = {
  __typename?: 'PostByIdRes';
  category?: Maybe<PostsPostCategory>;
  data: Posts;
};

export type PostTagInput = {
  id: Scalars['Float'];
  tagId: Scalars['Float'];
};

export type Posts = {
  __typename?: 'Posts';
  addLink?: Maybe<Scalars['String']>;
  author?: Maybe<Authors>;
  authors?: Maybe<Scalars['Int']>;
  bookmarkCount?: Maybe<Scalars['Int']>;
  bookmarkPosts?: Maybe<Array<BookmarkPost>>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  fullvideo?: Maybe<Scalars['String']>;
  heroimage?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  locale?: Maybe<Scalars['Int']>;
  mainlistShow: Scalars['Int'];
  prevcontent?: Maybe<Scalars['String']>;
  publish: Scalars['Int'];
  shares: Scalars['Int'];
  slug: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tags>>;
  tagsId?: Maybe<Scalars['String']>;
  tagsName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
  updatedAtContent?: Maybe<Scalars['Date']>;
  views: Scalars['Int'];
};

export type PostsInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type PostsPostCategory = {
  __typename?: 'PostsPostCategory';
  category_id: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  post_id: Scalars['Int'];
};

export type PostsResponse = {
  __typename?: 'PostsResponse';
  count: Scalars['Float'];
  data: Array<Posts>;
};

export type ProductDto = {
  __typename?: 'ProductDto';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type PushInput = {
  body: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  pushData?: InputMaybe<Scalars['String']>;
  pushTarget?: InputMaybe<Scalars['String']>;
  reservationDate?: InputMaybe<Scalars['String']>;
  reservationDay?: InputMaybe<Scalars['String']>;
  reservationTime?: InputMaybe<Scalars['String']>;
  reservationType?: InputMaybe<Scalars['String']>;
  reservationWeek?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalSendCount?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['Float']>;
};

export type PushOpenCountInput = {
  id: Scalars['Float'];
  reservationDate: Scalars['String'];
  reservationType: Scalars['String'];
};

export type PushUpdate = {
  body: Scalars['String'];
  id: Scalars['Float'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  pushData?: InputMaybe<Scalars['String']>;
  pushTarget?: InputMaybe<Scalars['String']>;
  reservationDate?: InputMaybe<Scalars['String']>;
  reservationDay?: InputMaybe<Scalars['String']>;
  reservationTime?: InputMaybe<Scalars['String']>;
  reservationType?: InputMaybe<Scalars['String']>;
  reservationWeek?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalSendCount?: InputMaybe<Scalars['Float']>;
};

export type PushesInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  reservationType: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
  type?: InputMaybe<Scalars['Float']>;
};

export type PushesResponse = {
  __typename?: 'PushesResponse';
  count: Scalars['Float'];
  data: Array<BillyPush>;
};

export type Query = {
  __typename?: 'Query';
  admins: AdminsResponse;
  appVersions: AppVersionsResponse;
  author: Authors;
  authors: AuthorsResponse;
  bedongReports: BedongReportResponse;
  boards: BoardsResponse;
  commuAiAbuses: CommuAbuseResponse;
  commuReplyAiAbuses: CommuReplyAbuseResponse;
  commuReports: CommuReportsResponse;
  commuUserBlocks: CommuUserBlocksResponse;
  commuUserReports: CommuUserReportsResponse;
  commus: CommusResponse;
  events: EventsResponse;
  getAppContentById: GetAppContentByIdRes;
  getAppContents: AppContentsResponse;
  getAppSplashById: GetSplashesByIdRes;
  getBillyPushCounts: BillyPushCountsResponse;
  getBoardById: GetBoardByIdRes;
  getEventById: GetEventByIdRes;
  getMarketingEvent: MarketingEventResponse;
  getMarketingEventInputLabel: MarketingEventInputLabelResponse;
  getPostById: PostByIdRes;
  getProducts: Array<ProductDto>;
  getPushById: GetPushByIdResponse;
  getPushOpenedCount: GetPushOpenedCountResponse;
  getPushes: PushesResponse;
  getRandomCouponById: GetRandomCouponByIdRes;
  me: UserSimpleResponse;
  ncpInfo?: Maybe<Scalars['String']>;
  noticeCommus: CommusNoticeResponse;
  posts: PostsResponse;
  randomCoupons: RandomCouponsResponse;
  reports: ReportsResponse;
  showBedongGroup: Array<BedongGroup>;
  showBedongNoticeGroup: BedongNoticeGroupResponse;
  showBedongNoticeType: BedongNoticeTypeResponse;
  showExcelDownload: ExcelResponse;
  showUserBlackList: ShowUserBlackListResponse;
  splashes: SplashesResponse;
  tags: TagsResponse;
  tokens: UsersResponse;
  users: UsersResponse;
};


export type QueryAdminsArgs = {
  input: AdminsInput;
};


export type QueryAppVersionsArgs = {
  input: AppVersionsInput;
};


export type QueryAuthorArgs = {
  id: Scalars['Float'];
};


export type QueryAuthorsArgs = {
  input: AuthorsInput;
};


export type QueryBedongReportsArgs = {
  input: BedongReportsInput;
};


export type QueryBoardsArgs = {
  input: BoardInput;
};


export type QueryCommuAiAbusesArgs = {
  input: CommuAbuseInput;
};


export type QueryCommuReplyAiAbusesArgs = {
  input: CommuReplyAbuseInput;
};


export type QueryCommuReportsArgs = {
  input: CommuReportsInput;
};


export type QueryCommuUserBlocksArgs = {
  input: CommuUserBlockRepostInput;
};


export type QueryCommuUserReportsArgs = {
  input: CommuUserBlockRepostInput;
};


export type QueryCommusArgs = {
  input: CommusInput;
};


export type QueryEventsArgs = {
  input: EventsInput;
};


export type QueryGetAppContentByIdArgs = {
  input: CommonIdInput;
};


export type QueryGetAppContentsArgs = {
  input: AppContentsInput;
};


export type QueryGetAppSplashByIdArgs = {
  input: CommonIdInput;
};


export type QueryGetBillyPushCountsArgs = {
  input: PushInput;
};


export type QueryGetBoardByIdArgs = {
  input: CommonIdInput;
};


export type QueryGetEventByIdArgs = {
  input: CommonIdInput;
};


export type QueryGetMarketingEventArgs = {
  input: GetMarketingEventInput;
};


export type QueryGetPostByIdArgs = {
  input: CommonIdInput;
};


export type QueryGetPushByIdArgs = {
  input: CommonIdInput;
};


export type QueryGetPushOpenedCountArgs = {
  input: PushOpenCountInput;
};


export type QueryGetPushesArgs = {
  input: PushesInput;
};


export type QueryGetRandomCouponByIdArgs = {
  input: CommonIdInput;
};


export type QueryNcpInfoArgs = {
  userId: Scalars['Float'];
};


export type QueryNoticeCommusArgs = {
  input: CommusNoticeInput;
};


export type QueryPostsArgs = {
  input: PostsInput;
};


export type QueryRandomCouponsArgs = {
  input: EventsInput;
};


export type QueryReportsArgs = {
  input: ReportsInput;
};


export type QueryShowUserBlackListArgs = {
  input: ShowUserBlackListInput;
};


export type QuerySplashesArgs = {
  input: SplashesInput;
};


export type QueryTagsArgs = {
  input: TagsInput;
};


export type QueryTokensArgs = {
  input: CommonIdInput;
};


export type QueryUsersArgs = {
  input: UsersInput;
};

export type RandomCouponEvents = {
  __typename?: 'RandomCouponEvents';
  couponArray: Array<CouponResponseInfo>;
  couponIds: Scalars['String'];
  coupons?: Maybe<Array<RandomCoupons>>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['Date'];
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type RandomCouponInput = {
  coupons?: InputMaybe<Array<CouponInfo>>;
  endDate?: InputMaybe<Scalars['Date']>;
  infoText: Scalars['String'];
  promotionText: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
};

export type RandomCouponUpdateInput = {
  coupons?: InputMaybe<Array<CouponInfo>>;
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Float'];
  infoText: Scalars['String'];
  promotionText: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
};

export type RandomCoupons = {
  __typename?: 'RandomCoupons';
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  event: RandomCouponEvents;
  eventId: Scalars['Int'];
  id: Scalars['Int'];
  isUsed: Scalars['Boolean'];
  quantity: Scalars['Int'];
  usedAt?: Maybe<Scalars['Date']>;
};

export type RandomCouponsResponse = {
  __typename?: 'RandomCouponsResponse';
  count: Scalars['Float'];
  data: Array<RandomCouponEvents>;
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  username: Scalars['String'];
};

export type ReportsInput = {
  commuId: Scalars['Float'];
  kind: Scalars['Float'];
};

export type ReportsResponse = {
  __typename?: 'ReportsResponse';
  data: Array<CommuReports>;
};

export type ShowUserBlackListInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type ShowUserBlackListResponse = {
  __typename?: 'ShowUserBlackListResponse';
  count: Scalars['Float'];
  data: Array<UserBlacklist>;
};

export type SplashesInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type SplashesResponse = {
  __typename?: 'SplashesResponse';
  count: Scalars['Float'];
  data: Array<AppSplash>;
};

export type Taggables = {
  __typename?: 'Taggables';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  tag_id: Scalars['Int'];
  taggable_id: Scalars['Int'];
  taggable_type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type Tags = {
  __typename?: 'Tags';
  Posts: Array<Posts>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  locale: Scalars['Int'];
  name: Scalars['String'];
  search_count: Scalars['Int'];
  taggables: Array<Taggables>;
  updatedAt: Scalars['Date'];
  use_count: Scalars['Int'];
};

export type TagsInput = {
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  tagname?: InputMaybe<Scalars['String']>;
};

export type TagsResponse = {
  __typename?: 'TagsResponse';
  data: Array<Tags>;
};

export type UpdateInput = {
  email: Scalars['String'];
  id: Scalars['Float'];
  isCommunityPermit: Scalars['Boolean'];
  name: Scalars['String'];
  photo?: InputMaybe<Scalars['String']>;
  role: Scalars['Float'];
};

export type UpdateMarketingEventInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  deleteImageIds?: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['String'];
  eventId: Scalars['Float'];
  eventName: Scalars['String'];
  eventType: Scalars['String'];
  exposedTargetGroup: Array<Scalars['String']>;
  exposedTargetScreen: Array<Scalars['String']>;
  landingScreenUrl: Scalars['String'];
  popupContentFirst?: InputMaybe<Scalars['String']>;
  popupContentSecond?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};

export type UpdatePostInput = {
  authors: Scalars['Float'];
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  isImageType?: InputMaybe<Scalars['String']>;
  portal?: InputMaybe<Scalars['Float']>;
  publish?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<ITags>>;
  title: Scalars['String'];
};

export type UpdateSplasheInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  language: Scalars['String'];
  originalName: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UploadS3Input = {
  location: Scalars['String'];
};

export type UserBlacklist = {
  __typename?: 'UserBlacklist';
  createdAt: Scalars['Date'];
  deletedAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  alarmConfirm: Scalars['Boolean'];
  chatwbabyConfirm?: Maybe<Scalars['Boolean']>;
  commuConfirm?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  eventConfirm: Scalars['Boolean'];
  hotdealConfirm?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  ncpInit: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['Date']>;
  user: Users;
  userId: Scalars['Int'];
};

export type UserSimpleResponse = {
  __typename?: 'UserSimpleResponse';
  /** 회원 이메일 */
  email: Scalars['String'];
  /** 회원 고유 ID */
  id: Scalars['Int'];
  /** 커뮤니티 권한 */
  options?: Maybe<Scalars['JSON']>;
  /** 회원 권한 */
  role: Scalars['Int'];
};

export type Users = {
  __typename?: 'Users';
  afterBabyname?: Maybe<Scalars['String']>;
  afterBirthday?: Maybe<Scalars['Date']>;
  babyGender: Scalars['Int'];
  babyname?: Maybe<Scalars['String']>;
  babytime?: Maybe<Scalars['Date']>;
  bedongReport?: Maybe<BedongReport>;
  birthday?: Maybe<Scalars['String']>;
  blockedAt?: Maybe<Scalars['Date']>;
  bookmarkPosts?: Maybe<Array<BookmarkPost>>;
  chatid: Scalars['Int'];
  checkBirth: Scalars['Boolean'];
  checkTwins: Scalars['Boolean'];
  commuDt?: Maybe<Scalars['String']>;
  commuNotice?: Maybe<CommuNotices>;
  commuNoticeSub?: Maybe<CommuNoticeSub>;
  commuTargetUserBlacklist?: Maybe<CommuUserBlacklist>;
  commuTargetUserReports?: Maybe<CommuUserReports>;
  commuUserBlacklist?: Maybe<CommuUserBlacklist>;
  commuUserReports?: Maybe<CommuUserReports>;
  commus?: Maybe<Commus>;
  commusSub?: Maybe<CommusSub>;
  createdAt?: Maybe<Scalars['Date']>;
  currentHashedRefreshToken?: Maybe<Scalars['String']>;
  duedate?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailUse?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['Date']>;
  familyMembers?: Maybe<Array<FamilyMember>>;
  firebaseTokens?: Maybe<Array<FirebaseTokens>>;
  id: Scalars['Int'];
  laborAt?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['Date']>;
  locale?: Maybe<Scalars['Int']>;
  mobileNumber?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Date']>;
  options?: Maybe<Scalars['JSON']>;
  parentGender: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  randomKey?: Maybe<Scalars['String']>;
  rememberToken?: Maybe<Scalars['String']>;
  reports?: Maybe<CommuReports>;
  role: Scalars['Int'];
  selfie?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userSettings?: Maybe<UserSettings>;
  username: Scalars['String'];
  usersSocials?: Maybe<Array<UsersSocial>>;
};

export type UsersInput = {
  filterModel?: InputMaybe<Scalars['JSONObject']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['JSONObject']>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count: Scalars['Float'];
  data: Array<Users>;
};

export type UsersSocial = {
  __typename?: 'UsersSocial';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  service: Scalars['String'];
  socialId: Scalars['String'];
  updatedAt: Scalars['Date'];
  user?: Maybe<Users>;
  userId: Scalars['Int'];
};

export type AdminsQueryVariables = Exact<{
  input: AdminsInput;
}>;


export type AdminsQuery = { __typename?: 'Query', admins: { __typename?: 'AdminsResponse', count: number, data: Array<{ __typename?: 'Users', id: number, username: string, email: string, photo?: string | null, selfie?: string | null, babyname?: string | null, duedate?: string | null, babyGender: number, parentGender: boolean, lastLogin?: any | null, updatedAt?: any | null, createdAt?: any | null }> } };

export type ChangeAdminPasswordMutationVariables = Exact<{
  input: AdminPasswordChangeInput;
}>;


export type ChangeAdminPasswordMutation = { __typename?: 'Mutation', changeAdminPassword: { __typename?: 'CommonSuccess', success: boolean } };

export type AdminCreateMutationVariables = Exact<{
  input: AdminCreateInput;
}>;


export type AdminCreateMutation = { __typename?: 'Mutation', adminCreate: { __typename?: 'CommonSuccess', success: boolean } };

export type GetAppContentsQueryVariables = Exact<{
  input: AppContentsInput;
}>;


export type GetAppContentsQuery = { __typename?: 'Query', getAppContents: { __typename?: 'AppContentsResponse', count: number, data: Array<{ __typename?: 'AppContents', id: number, name: string, type: number, screen?: string | null, content?: any | null, language: number, requiredVersion?: string | null, createdAt?: string | null, updatedAt?: string | null }> } };

export type GetAppContentByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetAppContentByIdQuery = { __typename?: 'Query', getAppContentById: { __typename?: 'GetAppContentByIdRes', data: { __typename?: 'AppContents', id: number, name: string, type: number, screen?: string | null, content?: any | null, language: number, requiredVersion?: string | null, createdAt?: string | null, updatedAt?: string | null } } };

export type CreateAppContentMutationVariables = Exact<{
  appContentCreateInput: AppContentsCreateInput;
}>;


export type CreateAppContentMutation = { __typename?: 'Mutation', createAppContent: { __typename?: 'CommonSuccess', success: boolean } };

export type EditAppContentMutationVariables = Exact<{
  appContentUpdateInput: AppContentsUpdateInput;
}>;


export type EditAppContentMutation = { __typename?: 'Mutation', editAppContent: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteAppContentMutationVariables = Exact<{
  appContentDeleteInput: CommonIdInput;
}>;


export type DeleteAppContentMutation = { __typename?: 'Mutation', deleteAppContent: { __typename?: 'CommonSuccess', success: boolean } };

export type LoginMutationVariables = Exact<{
  loginInput: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'Users', id: number, email: string, role: number, options?: any | null } } };

export type RegisterMutationVariables = Exact<{
  registerInput: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'Users', id: number, email: string, role: number, options?: any | null } } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'UserSimpleResponse', id: number, email: string, role: number, options?: any | null } };

export type AuthorsQueryVariables = Exact<{
  input: AuthorsInput;
}>;


export type AuthorsQuery = { __typename?: 'Query', authors: { __typename?: 'AuthorsResponse', count: number, data: Array<{ __typename?: 'Authors', id: number, name: string, photo?: string | null, link?: string | null, about?: string | null }> } };

export type AuthorMutationVariables = Exact<{
  authorCreateInput: AuthorCreateInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  froalaFiles?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type AuthorMutation = { __typename?: 'Mutation', author: { __typename?: 'Authors', id: number, name: string, photo?: string | null, link?: string | null, about?: string | null } };

export type DeleteAuthorMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteAuthorMutation = { __typename?: 'Mutation', deleteAuthor: { __typename?: 'CommonSuccess', success: boolean } };

export type ShowAuthorQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type ShowAuthorQuery = { __typename?: 'Query', author: { __typename?: 'Authors', id: number, name: string, photo?: string | null, link?: string | null, about?: string | null } };

export type BoardsQueryVariables = Exact<{
  input: BoardInput;
}>;


export type BoardsQuery = { __typename?: 'Query', boards: { __typename?: 'BoardsResponse', count: number, data: Array<{ __typename?: 'Boards', id: number, title: string, viewcnt?: number | null, content?: string | null, photo?: string | null, type: number, publish: boolean, createdAt: string, updatedAt: string }> } };

export type GetBoardByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetBoardByIdQuery = { __typename?: 'Query', getBoardById: { __typename?: 'GetBoardByIdRes', data: { __typename?: 'Boards', id: number, title: string, content?: string | null, photo?: string | null, type: number, publish: boolean } } };

export type CreateBoardMutationVariables = Exact<{
  boardCreateInput: BoardCreateInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreateBoardMutation = { __typename?: 'Mutation', createBoard: { __typename?: 'CommonSuccess', success: boolean } };

export type EditBoardMutationVariables = Exact<{
  boardUpdateInput: BoardUpdateInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type EditBoardMutation = { __typename?: 'Mutation', updateBoard: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteBoardMutationVariables = Exact<{
  boardDeleteInput: CommonIdInput;
}>;


export type DeleteBoardMutation = { __typename?: 'Mutation', deleteBoard: { __typename?: 'CommonSuccess', success: boolean } };

export type EditorUploadToS3MutationVariables = Exact<{
  uploadS3Input: UploadS3Input;
  file: Scalars['Upload'];
}>;


export type EditorUploadToS3Mutation = { __typename?: 'Mutation', editorUploadToS3: { __typename?: 'EditorS3Respone', link: string } };

export type EditorDeleteToS3MutationVariables = Exact<{
  uploadS3Input: UploadS3Input;
}>;


export type EditorDeleteToS3Mutation = { __typename?: 'Mutation', editorDeleteToS3: { __typename?: 'EditorS3Respone', link: string } };

export type CommusQueryVariables = Exact<{
  input: CommusInput;
}>;


export type CommusQuery = { __typename?: 'Query', commus: { __typename?: 'CommusResponse', count: number, data: Array<{ __typename?: 'Commus', id: number, commuDt?: string | null, title: string, body?: string | null, photo?: string | null, reactions?: number | null, likeCount?: number | null, hide: number, createdAt?: any | null, reportsCount?: number | null, users?: { __typename?: 'Users', id: number, email: string, username: string, selfie?: string | null, babyGender: number } | null, reports?: Array<{ __typename?: 'CommuReports', id: number, reportInfo1?: string | null, users?: { __typename?: 'Users', id: number, username: string } | null }> | null, adminsub?: Array<{ __typename?: 'CommusSub', id: number, commuId?: number | null, photo?: string | null, likeCount?: number | null, subId?: number | null, body?: string | null, hide: number, users?: { __typename?: 'Users', id: number, username: string } | null, reports?: Array<{ __typename?: 'CommuReports', id: number, reportInfo1?: string | null, users?: { __typename?: 'Users', id: number, username: string, selfie?: string | null } | null }> | null }> | null, bedongGroup?: { __typename?: 'BedongGroup', id: number, year?: number | null, month?: number | null, type: string, locale: number } | null }> } };

export type ReportsQueryVariables = Exact<{
  input: ReportsInput;
}>;


export type ReportsQuery = { __typename?: 'Query', reports: { __typename?: 'ReportsResponse', data: Array<{ __typename?: 'CommuReports', id: number, reportInfo1?: string | null, users?: { __typename?: 'Users', id: number, username: string, selfie?: string | null } | null }> } };

export type ShowBedongNoticeTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowBedongNoticeTypeQuery = { __typename?: 'Query', showBedongNoticeType: { __typename?: 'BedongNoticeTypeResponse', data: Array<{ __typename?: 'BedongNoticeType', id: number, type: string, title: string, color: string }> } };

export type CommuHideMutationVariables = Exact<{
  input: CommusHideInput;
}>;


export type CommuHideMutation = { __typename?: 'Mutation', commuHide: { __typename?: 'CommonSuccess', success: boolean } };

export type CommuReplyMutationVariables = Exact<{
  input: CommuReplyInput;
}>;


export type CommuReplyMutation = { __typename?: 'Mutation', commuReply: { __typename?: 'CommonSuccess', success: boolean } };

export type EditCommuMutationVariables = Exact<{
  input: EditCommuInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type EditCommuMutation = { __typename?: 'Mutation', editCommu: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteCommuMutationVariables = Exact<{
  input: DeleteCommuInput;
}>;


export type DeleteCommuMutation = { __typename?: 'Mutation', deleteCommu: { __typename?: 'CommonSuccess', success: boolean } };

export type EditReplyCommuMutationVariables = Exact<{
  input: EditCommuReplyInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type EditReplyCommuMutation = { __typename?: 'Mutation', editReplyCommu: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteReplyCommuMutationVariables = Exact<{
  input: DeleteCommuReplyInput;
}>;


export type DeleteReplyCommuMutation = { __typename?: 'Mutation', deleteReplyCommu: { __typename?: 'CommonSuccess', success: boolean } };

export type ShowBedongGroupQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowBedongGroupQuery = { __typename?: 'Query', showBedongGroup: Array<{ __typename?: 'BedongGroup', id: number, year?: number | null, month?: number | null, type: string, locale: number }> };

export type NoticeCommusQueryVariables = Exact<{
  input: CommusNoticeInput;
}>;


export type NoticeCommusQuery = { __typename?: 'Query', noticeCommus: { __typename?: 'CommusNoticeResponse', count: number, data: Array<{ __typename?: 'CommuNotices', id: number, kind: number, userId: number, commuDt?: string | null, title: string, body?: string | null, likeCount?: number | null, photo?: string | null, reactions?: number | null, hide: number, createdAt?: any | null, updatedAt?: any | null, commuNoticeReply?: Array<{ __typename?: 'CommuNoticeSub', id: number, subId?: number | null, commuId?: number | null, photo?: string | null, likeCount?: number | null, body: string, hide: number, createdAt?: any | null, updatedAt?: any | null, users?: { __typename?: 'Users', id: number, username: string, selfie?: string | null } | null }> | null, bedongNoticeType?: { __typename?: 'BedongNoticeType', id: number, type: string, title: string, color: string } | null, bedongNoticeGroup?: Array<{ __typename?: 'BedongNoticeGroup', bedongGroup?: { __typename?: 'BedongGroup', id: number, year?: number | null, month?: number | null, type: string, locale: number } | null }> | null }> } };

export type NoticeMutationVariables = Exact<{
  input: AddCommuNoticeInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type NoticeMutation = { __typename?: 'Mutation', notice: { __typename?: 'CommonSuccess', success: boolean } };

export type EditNoticeMutationVariables = Exact<{
  input: EditCommuNoticeInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  froalaImages?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type EditNoticeMutation = { __typename?: 'Mutation', editNotice: { __typename?: 'CommonSuccess', success: boolean } };

export type EditReplyCommuNoticeMutationVariables = Exact<{
  input: EditCommuReplyInput;
}>;


export type EditReplyCommuNoticeMutation = { __typename?: 'Mutation', editReplyCommuNotice: { __typename?: 'CommonSuccess', success: boolean } };

export type CommuNoticeReplyMutationVariables = Exact<{
  input: CommuReplyInput;
}>;


export type CommuNoticeReplyMutation = { __typename?: 'Mutation', commuNoticeReply: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteNoticeMutationVariables = Exact<{
  input: DeleteCommuInput;
}>;


export type DeleteNoticeMutation = { __typename?: 'Mutation', deleteNotice: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteReplyCommuNoticeMutationVariables = Exact<{
  input: DeleteCommuReplyInput;
}>;


export type DeleteReplyCommuNoticeMutation = { __typename?: 'Mutation', deleteReplyCommuNotice: { __typename?: 'CommonSuccess', success: boolean } };

export type CommuReportsQueryVariables = Exact<{
  input: CommuReportsInput;
}>;


export type CommuReportsQuery = { __typename?: 'Query', commuReports: { __typename?: 'CommuReportsResponse', count: number, data: Array<{ __typename?: 'CommuReports', id: number, commuOrSubId: number, reportInfo1?: string | null, createdAt?: any | null, users?: { __typename?: 'Users', id: number, username: string, commuDt?: string | null, selfie?: string | null } | null, commus?: { __typename?: 'Commus', id: number, title: string, body?: string | null, reactions?: number | null, hide: number, photo?: string | null, users?: { __typename?: 'Users', id: number, selfie?: string | null, username: string, commuDt?: string | null, createdAt?: any | null } | null } | null, commusSub?: { __typename?: 'CommusSub', id: number, body?: string | null, hide: number, users?: { __typename?: 'Users', id: number, selfie?: string | null, username: string, commuDt?: string | null, createdAt?: any | null } | null } | null }> } };

export type BedongReportsQueryVariables = Exact<{
  input: BedongReportsInput;
}>;


export type BedongReportsQuery = { __typename?: 'Query', bedongReports: { __typename?: 'BedongReportResponse', count: number, data: Array<{ __typename?: 'BedongReport', id: number, type: string, description: string, createdAt?: any | null, user?: { __typename?: 'Users', id: number, selfie?: string | null, username: string, createdAt?: any | null } | null, bedongPost?: { __typename?: 'Commus', id: number, title: string, body?: string | null, reactions?: number | null, hide: number, photo?: string | null, commuDt?: string | null, users?: { __typename?: 'Users', id: number, selfie?: string | null, username: string, commuDt?: string | null, createdAt?: any | null } | null } | null, bedongPostComment?: { __typename?: 'CommusSub', id: number, body?: string | null, hide: number, photo?: string | null, likeCount?: number | null, userId: number, commus?: { __typename?: 'Commus', id: number, title: string, body?: string | null, reactions?: number | null, hide: number, photo?: string | null, commuDt?: string | null } | null } | null, bedongNoticeComment?: { __typename?: 'CommuNoticeSub', id: number, body: string, hide: number, userId: number, commuNotice?: { __typename?: 'CommuNotices', id: number, title: string, body?: string | null, reactions?: number | null, hide: number, photo?: string | null, commuDt?: string | null } | null } | null }> } };

export type CommuUserReportsQueryVariables = Exact<{
  input: CommuUserBlockRepostInput;
}>;


export type CommuUserReportsQuery = { __typename?: 'Query', commuUserReports: { __typename?: 'CommuUserReportsResponse', count: number, data: Array<{ __typename?: 'CommuUserReports', id: number, userId: number, reportInfo1?: string | null, reportInfo2?: string | null, createdAt: any, user?: { __typename?: 'Users', id: number, username: string, commuDt?: string | null, selfie?: string | null } | null, target?: { __typename?: 'Users', id: number, username: string, commuDt?: string | null, selfie?: string | null } | null }> } };

export type CommuUserBlocksQueryVariables = Exact<{
  input: CommuUserBlockRepostInput;
}>;


export type CommuUserBlocksQuery = { __typename?: 'Query', commuUserBlocks: { __typename?: 'CommuUserBlocksResponse', count: number, data: Array<{ __typename?: 'CommuUserBlacklist', id: number, createdAt: any, user?: { __typename?: 'Users', id: number, username: string, commuDt?: string | null, selfie?: string | null } | null, target?: { __typename?: 'Users', id: number, username: string, commuDt?: string | null, selfie?: string | null } | null }> } };

export type ShowBedongNoticeGroupQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowBedongNoticeGroupQuery = { __typename?: 'Query', showBedongNoticeGroup: { __typename?: 'BedongNoticeGroupResponse', data: Array<{ __typename?: 'BedongGroup', id: number, year?: number | null, month?: number | null, type: string, locale: number, createdAt: any, updatedAt: any, deletedAt?: any | null }> } };

export type BedongPostDetailFragment = { __typename?: 'Commus', reactions?: number | null, id: number, hide: number, users?: { __typename?: 'Users', id: number, nickname?: string | null } | null };

export type CommuAiAbusesQueryVariables = Exact<{
  input: CommuAbuseInput;
}>;


export type CommuAiAbusesQuery = { __typename?: 'Query', commuAiAbuses: { __typename?: 'CommuAbuseResponse', count: number, data: Array<{ __typename?: 'CommusAbuseEntity', id: number, commuId: number, title?: string | null, body?: string | null, createdAt: any, updatedAt: any, commus?: { __typename?: 'Commus', reactions?: number | null, id: number, hide: number, users?: { __typename?: 'Users', id: number, nickname?: string | null } | null } | null }> } };

export type CommuAiAbusesToggleMutationVariables = Exact<{
  input: AbuseAiInput;
}>;


export type CommuAiAbusesToggleMutation = { __typename?: 'Mutation', commuAiAbusesToggle: { __typename?: 'CommonSuccess', success: boolean } };

export type CommentDetailFragment = { __typename?: 'CommusSub', id: number, hide: number, commus?: { __typename?: 'Commus', title: string, body?: string | null, commuDt?: string | null } | null, users?: { __typename?: 'Users', id: number, nickname?: string | null } | null };

export type CommuReplyAiAbusesQueryVariables = Exact<{
  input: CommuReplyAbuseInput;
}>;


export type CommuReplyAiAbusesQuery = { __typename?: 'Query', commuReplyAiAbuses: { __typename?: 'CommuReplyAbuseResponse', count: number, data: Array<{ __typename?: 'CommusReplyAbuseEntity', id: number, replyId: number, body?: string | null, createdAt: any, updatedAt: any, commusReply?: { __typename?: 'CommusSub', id: number, hide: number, commus?: { __typename?: 'Commus', title: string, body?: string | null, commuDt?: string | null } | null, users?: { __typename?: 'Users', id: number, nickname?: string | null } | null } | null }> } };

export type CommuReplyAiAbusesToggleMutationVariables = Exact<{
  input: AbuseReplyAiInput;
}>;


export type CommuReplyAiAbusesToggleMutation = { __typename?: 'Mutation', commuReplyAiAbusesToggle: { __typename?: 'CommonSuccess', success: boolean } };

export type CreateRandomCouponMutationVariables = Exact<{
  input: RandomCouponInput;
}>;


export type CreateRandomCouponMutation = { __typename?: 'Mutation', createRandomCoupon: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteRandomCouponMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type DeleteRandomCouponMutation = { __typename?: 'Mutation', deleteRandomCoupon: { __typename?: 'CommonSuccess', success: boolean } };

export type EventsQueryVariables = Exact<{
  input: EventsInput;
}>;


export type EventsQuery = { __typename?: 'Query', events: { __typename?: 'EventsResponse', count: number, data: Array<{ __typename?: 'Events', id: number, start: any, end: any, title: string, content?: string | null, type: number, createdAt: any, updatedAt: any }> } };

export type CreateEventMutationVariables = Exact<{
  input: EventInput;
  froalaImages?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'CommonSuccess', success: boolean } };

export type UpdateEventMutationVariables = Exact<{
  input: EventUpdateInput;
  froalaImages?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteEventMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: { __typename?: 'CommonSuccess', success: boolean } };

export type GetEventByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetEventByIdQuery = { __typename?: 'Query', getEventById: { __typename?: 'GetEventByIdRes', data: { __typename?: 'Events', id: number, title: string, content?: string | null, type: number, start: any, end: any } } };

export type GetRandomCouponByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetRandomCouponByIdQuery = { __typename?: 'Query', getRandomCouponById: { __typename?: 'GetRandomCouponByIdRes', data: { __typename?: 'RandomCouponEvents', id: number, title: string, startDate: any, endDate: any, description?: string | null, notes?: string | null, couponArray: Array<{ __typename?: 'CouponResponseInfo', couponCd: string, quantity: number }> } } };

export type HideAllSeasonalTagMutationVariables = Exact<{
  input: PostArrayInput;
}>;


export type HideAllSeasonalTagMutation = { __typename?: 'Mutation', hideAllSeasonalTag: { __typename?: 'CommonSuccess', success: boolean } };

export type HideSeasonalTagMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type HideSeasonalTagMutation = { __typename?: 'Mutation', hideSeasonalTag: { __typename?: 'CommonSuccess', success: boolean } };

export type ImageUploadMutationVariables = Exact<{
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type ImageUploadMutation = { __typename?: 'Mutation', imageUpload: Array<{ __typename?: 'ImageUploadResponseDto', filename: string, mimetype: string, encoding: string, fieldName: string, uploadImageLink: string }> };

export type MarketingEventImageFragment = { __typename?: 'MarketingEventImage', id: number, originalName: string, resourceAccessPath: string, extension: string, createdAt: any, updatedAt: any };

export type LabelValueFragment = { __typename?: 'LabelValueResponseDto', label: string, value: string };

export type GetMarketingEventQueryVariables = Exact<{
  input: GetMarketingEventInput;
}>;


export type GetMarketingEventQuery = { __typename?: 'Query', getMarketingEvent: { __typename?: 'MarketingEventResponse', count: number, data: Array<{ __typename?: 'MarketingEventResponseDto', id: number, eventType: string, eventName: string, start?: any | null, end?: any | null, createdAt: any, updatedAt: any, landingScreenUrl?: string | null, dialogTitle?: string | null, dialogContent?: string | null, backgroundColor?: string | null, exposedScreen: Array<{ __typename?: 'LabelValueResponseDto', label: string, value: string }>, exposedTargetGroup?: Array<{ __typename?: 'LabelValueResponseDto', label: string, value: string }> | null, marketingEventImages: Array<{ __typename?: 'MarketingEventImage', id: number, originalName: string, resourceAccessPath: string, extension: string, createdAt: any, updatedAt: any }>, period: { __typename?: 'Period', start: any, end: any } }> } };

export type InputFragment = { __typename?: 'InputLabelWithValue', label: string, value: string };

export type GetMarketingEventInputLabelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarketingEventInputLabelQuery = { __typename?: 'Query', getMarketingEventInputLabel: { __typename?: 'MarketingEventInputLabelResponse', eventType: Array<{ __typename?: 'InputLabelWithValue', label: string, value: string }>, exposedScreen: Array<{ __typename?: 'InputLabelWithValue', label: string, value: string }>, exposedTargetGroup: Array<{ __typename?: 'InputLabelWithValue', label: string, value: string }> } };

export type CreateMarketingEventMutationVariables = Exact<{
  createInput: CreateMarketingEventInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreateMarketingEventMutation = { __typename?: 'Mutation', createMarketingEvent: { __typename?: 'CommonSuccess', success: boolean } };

export type UpdateMarketingEventInputMutationVariables = Exact<{
  updateInput: UpdateMarketingEventInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type UpdateMarketingEventInputMutation = { __typename?: 'Mutation', updateMarketingEvent: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteMarketingEventMutationVariables = Exact<{
  marketingEventDeleteInput: CommonIdInput;
}>;


export type DeleteMarketingEventMutation = { __typename?: 'Mutation', deleteMarketingEvent: { __typename?: 'CommonSuccess', success: boolean } };

export type NcpInfoQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;


export type NcpInfoQuery = { __typename?: 'Query', ncpInfo?: string | null };

export type MobileAuthMutationVariables = Exact<{
  type: Scalars['Float'];
  id: Scalars['Float'];
  mobileNumber: Scalars['String'];
}>;


export type MobileAuthMutation = { __typename?: 'Mutation', mobileAuth: { __typename?: 'CommonSuccess', success: boolean } };

export type TagsQueryVariables = Exact<{
  input: TagsInput;
}>;


export type TagsQuery = { __typename?: 'Query', tags: { __typename?: 'TagsResponse', data: Array<{ __typename?: 'Tags', id: number, name: string }> } };

export type PostsQueryVariables = Exact<{
  input: PostsInput;
}>;


export type PostsQuery = { __typename?: 'Query', posts: { __typename?: 'PostsResponse', count: number, data: Array<{ __typename?: 'Posts', id: number, title: string, heroimage?: string | null, content?: string | null, publish: number, tagsName?: string | null, tagsId?: string | null, views: number, bookmarkCount?: number | null, createdAt: any, updatedAt: any, author?: { __typename?: 'Authors', id: number, name: string, photo?: string | null } | null }> } };

export type GetPostByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetPostByIdQuery = { __typename?: 'Query', getPostById: { __typename?: 'PostByIdRes', data: { __typename?: 'Posts', id: number, title: string, heroimage?: string | null, content?: string | null, publish: number, views: number, bookmarkCount?: number | null, shares: number, createdAt: any, updatedAt: any, author?: { __typename?: 'Authors', id: number, name: string, photo?: string | null } | null, tags?: Array<{ __typename?: 'Tags', id: number, name: string }> | null }, category?: { __typename?: 'PostsPostCategory', category_id: number } | null } };

export type CreatePostMutationVariables = Exact<{
  input: CreatePostInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreatePostMutation = { __typename?: 'Mutation', createPost: { __typename?: 'CommonSuccess', success: boolean } };

export type UpdatePostMutationVariables = Exact<{
  input: UpdatePostInput;
  files?: InputMaybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type UpdatePostMutation = { __typename?: 'Mutation', updatePost: { __typename?: 'CommonSuccess', success: boolean } };

export type DeletePostMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deletePost: { __typename?: 'CommonSuccess', success: boolean } };

export type DeletePostTagMutationVariables = Exact<{
  input: PostTagInput;
}>;


export type DeletePostTagMutation = { __typename?: 'Mutation', deletePostTag: { __typename?: 'CommonSuccess', success: boolean } };

export type CreatePushMutationVariables = Exact<{
  input: PushInput;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type CreatePushMutation = { __typename?: 'Mutation', createPush: { __typename?: 'CommonSuccess', success: boolean } };

export type TestPushMutationVariables = Exact<{
  input: PushInput;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type TestPushMutation = { __typename?: 'Mutation', testPush: { __typename?: 'CommonSuccess', success: boolean } };

export type UpdatePushMutationVariables = Exact<{
  input: PushUpdate;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdatePushMutation = { __typename?: 'Mutation', updatePush: { __typename?: 'CommonSuccess', success: boolean } };

export type DeletePushMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type DeletePushMutation = { __typename?: 'Mutation', deletePush: { __typename?: 'CommonSuccess', success: boolean } };

export type GetPushOpenedCountQueryVariables = Exact<{
  input: PushOpenCountInput;
}>;


export type GetPushOpenedCountQuery = { __typename?: 'Query', getPushOpenedCount: { __typename?: 'GetPushOpenedCountResponse', count?: number | null } };

export type GetPushesQueryVariables = Exact<{
  input: PushesInput;
}>;


export type GetPushesQuery = { __typename?: 'Query', getPushes: { __typename?: 'PushesResponse', count: number, data: Array<{ __typename?: 'BillyPush', id: number, title: string, body: string, image?: string | null, cronExpression?: string | null, reservationType?: string | null, reservationWeek?: number | null, reservationDate?: string | null, reservationTime?: string | null, isActive: boolean, isSchedule: boolean, createdAt: any, updatedAt: any, totalSendCount?: number | null, sendCount?: number | null }> } };

export type GetPushByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetPushByIdQuery = { __typename?: 'Query', getPushById: { __typename?: 'GetPushByIdResponse', data: { __typename?: 'BillyPush', id: number, title: string, body: string, image?: string | null, pushTarget?: string | null, cronExpression?: string | null, pushData?: string | null, reservationType?: string | null, reservationDay?: Array<number> | null, reservationWeek?: number | null, reservationTime?: string | null, reservationDate?: string | null, isActive: boolean, createdAt: any, updatedAt: any } } };

export type GetBillyPushCountsQueryVariables = Exact<{
  input: PushInput;
}>;


export type GetBillyPushCountsQuery = { __typename?: 'Query', getBillyPushCounts: { __typename?: 'BillyPushCountsResponse', count: number } };

export type RandomCouponsQueryVariables = Exact<{
  input: EventsInput;
}>;


export type RandomCouponsQuery = { __typename?: 'Query', randomCoupons: { __typename?: 'RandomCouponsResponse', count: number, data: Array<{ __typename?: 'RandomCouponEvents', id: number, title: string, startDate: any, endDate: any, description?: string | null, createdAt: any, updatedAt: any, couponIds: string }> } };

export type ShowAllSeasonalTagMutationVariables = Exact<{
  input: PostArrayInput;
}>;


export type ShowAllSeasonalTagMutation = { __typename?: 'Mutation', showAllSeasonalTag: { __typename?: 'CommonSuccess', success: boolean } };

export type ShowSeasonalTagMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type ShowSeasonalTagMutation = { __typename?: 'Mutation', showSeasonalTag: { __typename?: 'CommonSuccess', success: boolean } };

export type SplashesQueryVariables = Exact<{
  input: SplashesInput;
}>;


export type SplashesQuery = { __typename?: 'Query', splashes: { __typename?: 'SplashesResponse', count: number, data: Array<{ __typename?: 'AppSplash', id: number, language: string, start?: any | null, end?: any | null, originalName: string, assumedName: string, accessPath: string, extension: string, length: number, isDefault: boolean, createdAt: any, updatedAt: any }> } };

export type GetAppSplashByIdQueryVariables = Exact<{
  input: CommonIdInput;
}>;


export type GetAppSplashByIdQuery = { __typename?: 'Query', getAppSplashById: { __typename?: 'GetSplashesByIdRes', data: { __typename?: 'AppSplash', id: number, language: string, start?: any | null, end?: any | null, originalName: string, assumedName: string, accessPath: string, extension: string, length: number, isDefault: boolean, createdAt: any, updatedAt: any } } };

export type CreateSplashMutationVariables = Exact<{
  input: CreateSplasheInput;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateSplashMutation = { __typename?: 'Mutation', createSplash: { __typename?: 'CommonSuccess', success: boolean } };

export type UpdateSplashMutationVariables = Exact<{
  input: UpdateSplasheInput;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateSplashMutation = { __typename?: 'Mutation', updateSplash: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteSplashMutationVariables = Exact<{
  splashDeleteInput: CommonIdInput;
}>;


export type DeleteSplashMutation = { __typename?: 'Mutation', deleteSplash: { __typename?: 'CommonSuccess', success: boolean } };

export type UpdateRandomCouponMutationVariables = Exact<{
  input: RandomCouponUpdateInput;
}>;


export type UpdateRandomCouponMutation = { __typename?: 'Mutation', updateRandomCoupon: { __typename?: 'CommonSuccess', success: boolean } };

export type UsersQueryVariables = Exact<{
  input: UsersInput;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number, data: Array<{ __typename?: 'Users', id: number, username: string, email: string, photo?: string | null, selfie?: string | null, babyname?: string | null, duedate?: string | null, babyGender: number, parentGender: boolean, lastLogin?: any | null, updatedAt?: any | null, createdAt?: any | null, emailUse?: string | null, blockedAt?: any | null, familyMembers?: Array<{ __typename?: 'FamilyMember', relationType: number, baby?: Array<{ __typename?: 'Baby', babyname?: string | null, duedate?: any | null, babyGender?: number | null }> | null }> | null, usersSocials?: Array<{ __typename?: 'UsersSocial', service: string }> | null, userSettings?: { __typename?: 'UserSettings', id: number, userId: number, eventConfirm: boolean, alarmConfirm: boolean, createdAt?: any | null, updatedAt?: any | null, chatwbabyConfirm?: boolean | null, commuConfirm?: boolean | null, hotdealConfirm?: boolean | null, ncpInit: boolean } | null, firebaseTokens?: Array<{ __typename?: 'FirebaseTokens', id: number, userId?: number | null, fcmToken?: string | null }> | null }> } };

export type DeleteUserMutationVariables = Exact<{
  input: CommonIdInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'CommonSuccess', success: boolean } };

export type ChangePasswordMutationVariables = Exact<{
  input: PasswordChangeInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'CommonSuccess', success: boolean } };

export type ShowExcelDownloadQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowExcelDownloadQuery = { __typename?: 'Query', showExcelDownload: { __typename?: 'ExcelResponse', buffer: string } };

export type BedongBlockUserMutationVariables = Exact<{
  input: BedongBlockUserInput;
}>;


export type BedongBlockUserMutation = { __typename?: 'Mutation', bedongBlockUser: { __typename?: 'CommonSuccess', success: boolean } };

export type ShowUserBlackListQueryVariables = Exact<{
  input: ShowUserBlackListInput;
}>;


export type ShowUserBlackListQuery = { __typename?: 'Query', showUserBlackList: { __typename?: 'ShowUserBlackListResponse', count: number, data: Array<{ __typename?: 'UserBlacklist', id: number, email: string, createdAt: any, updatedAt: any }> } };

export type AddBlackListEmailMutationVariables = Exact<{
  input: AddBlackListEmailInput;
}>;


export type AddBlackListEmailMutation = { __typename?: 'Mutation', addBlackListEmail: { __typename?: 'CommonSuccess', success: boolean } };

export type DeleteBlackListEmailMutationVariables = Exact<{
  input: DeleteBlackListEmailInput;
}>;


export type DeleteBlackListEmailMutation = { __typename?: 'Mutation', deleteBlackListEmail: { __typename?: 'CommonSuccess', success: boolean } };

export const BedongPostDetailFragmentDoc = gql`
    fragment BedongPostDetail on Commus {
  reactions
  id
  hide
  users {
    id
    nickname
  }
}
    `;
export const CommentDetailFragmentDoc = gql`
    fragment CommentDetail on CommusSub {
  id
  hide
  commus {
    title
    body
    commuDt
  }
  users {
    id
    nickname
  }
}
    `;
export const MarketingEventImageFragmentDoc = gql`
    fragment MarketingEventImage on MarketingEventImage {
  id
  originalName
  resourceAccessPath
  extension
  createdAt
  updatedAt
}
    `;
export const LabelValueFragmentDoc = gql`
    fragment LabelValue on LabelValueResponseDto {
  label
  value
}
    `;
export const InputFragmentDoc = gql`
    fragment Input on InputLabelWithValue {
  label
  value
}
    `;
export const AdminsDocument = gql`
    query admins($input: AdminsInput!) {
  admins(input: $input) {
    data {
      id
      username
      email
      photo
      selfie
      babyname
      duedate
      babyGender
      parentGender
      lastLogin
      updatedAt
      createdAt
    }
    count
  }
}
    `;

/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminsQuery(baseOptions: Apollo.QueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
      }
export function useAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsQueryResult = Apollo.QueryResult<AdminsQuery, AdminsQueryVariables>;
export const ChangeAdminPasswordDocument = gql`
    mutation changeAdminPassword($input: AdminPasswordChangeInput!) {
  changeAdminPassword(input: $input) {
    success
  }
}
    `;
export type ChangeAdminPasswordMutationFn = Apollo.MutationFunction<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>;

/**
 * __useChangeAdminPasswordMutation__
 *
 * To run a mutation, you first call `useChangeAdminPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdminPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdminPasswordMutation, { data, loading, error }] = useChangeAdminPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeAdminPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>(ChangeAdminPasswordDocument, options);
      }
export type ChangeAdminPasswordMutationHookResult = ReturnType<typeof useChangeAdminPasswordMutation>;
export type ChangeAdminPasswordMutationResult = Apollo.MutationResult<ChangeAdminPasswordMutation>;
export type ChangeAdminPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>;
export const AdminCreateDocument = gql`
    mutation adminCreate($input: AdminCreateInput!) {
  adminCreate(input: $input) {
    success
  }
}
    `;
export type AdminCreateMutationFn = Apollo.MutationFunction<AdminCreateMutation, AdminCreateMutationVariables>;

/**
 * __useAdminCreateMutation__
 *
 * To run a mutation, you first call `useAdminCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateMutation, { data, loading, error }] = useAdminCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateMutation, AdminCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateMutation, AdminCreateMutationVariables>(AdminCreateDocument, options);
      }
export type AdminCreateMutationHookResult = ReturnType<typeof useAdminCreateMutation>;
export type AdminCreateMutationResult = Apollo.MutationResult<AdminCreateMutation>;
export type AdminCreateMutationOptions = Apollo.BaseMutationOptions<AdminCreateMutation, AdminCreateMutationVariables>;
export const GetAppContentsDocument = gql`
    query getAppContents($input: AppContentsInput!) {
  getAppContents(input: $input) {
    data {
      id
      name
      type
      screen
      content
      language
      requiredVersion
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useGetAppContentsQuery__
 *
 * To run a query within a React component, call `useGetAppContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppContentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAppContentsQuery(baseOptions: Apollo.QueryHookOptions<GetAppContentsQuery, GetAppContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppContentsQuery, GetAppContentsQueryVariables>(GetAppContentsDocument, options);
      }
export function useGetAppContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppContentsQuery, GetAppContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppContentsQuery, GetAppContentsQueryVariables>(GetAppContentsDocument, options);
        }
export type GetAppContentsQueryHookResult = ReturnType<typeof useGetAppContentsQuery>;
export type GetAppContentsLazyQueryHookResult = ReturnType<typeof useGetAppContentsLazyQuery>;
export type GetAppContentsQueryResult = Apollo.QueryResult<GetAppContentsQuery, GetAppContentsQueryVariables>;
export const GetAppContentByIdDocument = gql`
    query getAppContentById($input: CommonIdInput!) {
  getAppContentById(input: $input) {
    data {
      id
      name
      type
      screen
      content
      language
      requiredVersion
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAppContentByIdQuery__
 *
 * To run a query within a React component, call `useGetAppContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppContentByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAppContentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAppContentByIdQuery, GetAppContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppContentByIdQuery, GetAppContentByIdQueryVariables>(GetAppContentByIdDocument, options);
      }
export function useGetAppContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppContentByIdQuery, GetAppContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppContentByIdQuery, GetAppContentByIdQueryVariables>(GetAppContentByIdDocument, options);
        }
export type GetAppContentByIdQueryHookResult = ReturnType<typeof useGetAppContentByIdQuery>;
export type GetAppContentByIdLazyQueryHookResult = ReturnType<typeof useGetAppContentByIdLazyQuery>;
export type GetAppContentByIdQueryResult = Apollo.QueryResult<GetAppContentByIdQuery, GetAppContentByIdQueryVariables>;
export const CreateAppContentDocument = gql`
    mutation createAppContent($appContentCreateInput: AppContentsCreateInput!) {
  createAppContent(appContentCreateInput: $appContentCreateInput) {
    success
  }
}
    `;
export type CreateAppContentMutationFn = Apollo.MutationFunction<CreateAppContentMutation, CreateAppContentMutationVariables>;

/**
 * __useCreateAppContentMutation__
 *
 * To run a mutation, you first call `useCreateAppContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppContentMutation, { data, loading, error }] = useCreateAppContentMutation({
 *   variables: {
 *      appContentCreateInput: // value for 'appContentCreateInput'
 *   },
 * });
 */
export function useCreateAppContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppContentMutation, CreateAppContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppContentMutation, CreateAppContentMutationVariables>(CreateAppContentDocument, options);
      }
export type CreateAppContentMutationHookResult = ReturnType<typeof useCreateAppContentMutation>;
export type CreateAppContentMutationResult = Apollo.MutationResult<CreateAppContentMutation>;
export type CreateAppContentMutationOptions = Apollo.BaseMutationOptions<CreateAppContentMutation, CreateAppContentMutationVariables>;
export const EditAppContentDocument = gql`
    mutation editAppContent($appContentUpdateInput: AppContentsUpdateInput!) {
  editAppContent(appContentUpdateInput: $appContentUpdateInput) {
    success
  }
}
    `;
export type EditAppContentMutationFn = Apollo.MutationFunction<EditAppContentMutation, EditAppContentMutationVariables>;

/**
 * __useEditAppContentMutation__
 *
 * To run a mutation, you first call `useEditAppContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAppContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAppContentMutation, { data, loading, error }] = useEditAppContentMutation({
 *   variables: {
 *      appContentUpdateInput: // value for 'appContentUpdateInput'
 *   },
 * });
 */
export function useEditAppContentMutation(baseOptions?: Apollo.MutationHookOptions<EditAppContentMutation, EditAppContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAppContentMutation, EditAppContentMutationVariables>(EditAppContentDocument, options);
      }
export type EditAppContentMutationHookResult = ReturnType<typeof useEditAppContentMutation>;
export type EditAppContentMutationResult = Apollo.MutationResult<EditAppContentMutation>;
export type EditAppContentMutationOptions = Apollo.BaseMutationOptions<EditAppContentMutation, EditAppContentMutationVariables>;
export const DeleteAppContentDocument = gql`
    mutation deleteAppContent($appContentDeleteInput: CommonIdInput!) {
  deleteAppContent(appContentDeleteInput: $appContentDeleteInput) {
    success
  }
}
    `;
export type DeleteAppContentMutationFn = Apollo.MutationFunction<DeleteAppContentMutation, DeleteAppContentMutationVariables>;

/**
 * __useDeleteAppContentMutation__
 *
 * To run a mutation, you first call `useDeleteAppContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppContentMutation, { data, loading, error }] = useDeleteAppContentMutation({
 *   variables: {
 *      appContentDeleteInput: // value for 'appContentDeleteInput'
 *   },
 * });
 */
export function useDeleteAppContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppContentMutation, DeleteAppContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppContentMutation, DeleteAppContentMutationVariables>(DeleteAppContentDocument, options);
      }
export type DeleteAppContentMutationHookResult = ReturnType<typeof useDeleteAppContentMutation>;
export type DeleteAppContentMutationResult = Apollo.MutationResult<DeleteAppContentMutation>;
export type DeleteAppContentMutationOptions = Apollo.BaseMutationOptions<DeleteAppContentMutation, DeleteAppContentMutationVariables>;
export const LoginDocument = gql`
    mutation Login($loginInput: LoginInput!) {
  login(loginInput: $loginInput) {
    accessToken
    user {
      id
      email
      role
      options
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($registerInput: RegisterInput!) {
  register(registerInput: $registerInput) {
    accessToken
    user {
      id
      email
      role
      options
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      registerInput: // value for 'registerInput'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    role
    options
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AuthorsDocument = gql`
    query Authors($input: AuthorsInput!) {
  authors(input: $input) {
    data {
      id
      name
      photo
      link
      about
    }
    count
  }
}
    `;

/**
 * __useAuthorsQuery__
 *
 * To run a query within a React component, call `useAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorsQuery(baseOptions: Apollo.QueryHookOptions<AuthorsQuery, AuthorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorsQuery, AuthorsQueryVariables>(AuthorsDocument, options);
      }
export function useAuthorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorsQuery, AuthorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorsQuery, AuthorsQueryVariables>(AuthorsDocument, options);
        }
export type AuthorsQueryHookResult = ReturnType<typeof useAuthorsQuery>;
export type AuthorsLazyQueryHookResult = ReturnType<typeof useAuthorsLazyQuery>;
export type AuthorsQueryResult = Apollo.QueryResult<AuthorsQuery, AuthorsQueryVariables>;
export const AuthorDocument = gql`
    mutation Author($authorCreateInput: AuthorCreateInput!, $files: [Upload!], $froalaFiles: [Upload!]) {
  author(
    authorCreateInput: $authorCreateInput
    files: $files
    froalaFiles: $froalaFiles
  ) {
    id
    name
    photo
    link
    about
  }
}
    `;
export type AuthorMutationFn = Apollo.MutationFunction<AuthorMutation, AuthorMutationVariables>;

/**
 * __useAuthorMutation__
 *
 * To run a mutation, you first call `useAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorMutation, { data, loading, error }] = useAuthorMutation({
 *   variables: {
 *      authorCreateInput: // value for 'authorCreateInput'
 *      files: // value for 'files'
 *      froalaFiles: // value for 'froalaFiles'
 *   },
 * });
 */
export function useAuthorMutation(baseOptions?: Apollo.MutationHookOptions<AuthorMutation, AuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorMutation, AuthorMutationVariables>(AuthorDocument, options);
      }
export type AuthorMutationHookResult = ReturnType<typeof useAuthorMutation>;
export type AuthorMutationResult = Apollo.MutationResult<AuthorMutation>;
export type AuthorMutationOptions = Apollo.BaseMutationOptions<AuthorMutation, AuthorMutationVariables>;
export const DeleteAuthorDocument = gql`
    mutation deleteAuthor($id: Float!) {
  deleteAuthor(id: $id) {
    success
  }
}
    `;
export type DeleteAuthorMutationFn = Apollo.MutationFunction<DeleteAuthorMutation, DeleteAuthorMutationVariables>;

/**
 * __useDeleteAuthorMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthorMutation, { data, loading, error }] = useDeleteAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAuthorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuthorMutation, DeleteAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuthorMutation, DeleteAuthorMutationVariables>(DeleteAuthorDocument, options);
      }
export type DeleteAuthorMutationHookResult = ReturnType<typeof useDeleteAuthorMutation>;
export type DeleteAuthorMutationResult = Apollo.MutationResult<DeleteAuthorMutation>;
export type DeleteAuthorMutationOptions = Apollo.BaseMutationOptions<DeleteAuthorMutation, DeleteAuthorMutationVariables>;
export const ShowAuthorDocument = gql`
    query showAuthor($id: Float!) {
  author(id: $id) {
    id
    name
    photo
    link
    about
  }
}
    `;

/**
 * __useShowAuthorQuery__
 *
 * To run a query within a React component, call `useShowAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowAuthorQuery(baseOptions: Apollo.QueryHookOptions<ShowAuthorQuery, ShowAuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowAuthorQuery, ShowAuthorQueryVariables>(ShowAuthorDocument, options);
      }
export function useShowAuthorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowAuthorQuery, ShowAuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowAuthorQuery, ShowAuthorQueryVariables>(ShowAuthorDocument, options);
        }
export type ShowAuthorQueryHookResult = ReturnType<typeof useShowAuthorQuery>;
export type ShowAuthorLazyQueryHookResult = ReturnType<typeof useShowAuthorLazyQuery>;
export type ShowAuthorQueryResult = Apollo.QueryResult<ShowAuthorQuery, ShowAuthorQueryVariables>;
export const BoardsDocument = gql`
    query boards($input: BoardInput!) {
  boards(input: $input) {
    data {
      id
      title
      viewcnt
      content
      photo
      type
      publish
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useBoardsQuery__
 *
 * To run a query within a React component, call `useBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBoardsQuery(baseOptions: Apollo.QueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
      }
export function useBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
        }
export type BoardsQueryHookResult = ReturnType<typeof useBoardsQuery>;
export type BoardsLazyQueryHookResult = ReturnType<typeof useBoardsLazyQuery>;
export type BoardsQueryResult = Apollo.QueryResult<BoardsQuery, BoardsQueryVariables>;
export const GetBoardByIdDocument = gql`
    query getBoardById($input: CommonIdInput!) {
  getBoardById(input: $input) {
    data {
      id
      title
      content
      photo
      type
      publish
    }
  }
}
    `;

/**
 * __useGetBoardByIdQuery__
 *
 * To run a query within a React component, call `useGetBoardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoardByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBoardByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBoardByIdQuery, GetBoardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoardByIdQuery, GetBoardByIdQueryVariables>(GetBoardByIdDocument, options);
      }
export function useGetBoardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoardByIdQuery, GetBoardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoardByIdQuery, GetBoardByIdQueryVariables>(GetBoardByIdDocument, options);
        }
export type GetBoardByIdQueryHookResult = ReturnType<typeof useGetBoardByIdQuery>;
export type GetBoardByIdLazyQueryHookResult = ReturnType<typeof useGetBoardByIdLazyQuery>;
export type GetBoardByIdQueryResult = Apollo.QueryResult<GetBoardByIdQuery, GetBoardByIdQueryVariables>;
export const CreateBoardDocument = gql`
    mutation createBoard($boardCreateInput: BoardCreateInput!, $files: [Upload!], $froalaImages: [Upload!]) {
  createBoard(
    boardCreateInput: $boardCreateInput
    files: $files
    froalaImages: $froalaImages
  ) {
    success
  }
}
    `;
export type CreateBoardMutationFn = Apollo.MutationFunction<CreateBoardMutation, CreateBoardMutationVariables>;

/**
 * __useCreateBoardMutation__
 *
 * To run a mutation, you first call `useCreateBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBoardMutation, { data, loading, error }] = useCreateBoardMutation({
 *   variables: {
 *      boardCreateInput: // value for 'boardCreateInput'
 *      files: // value for 'files'
 *      froalaImages: // value for 'froalaImages'
 *   },
 * });
 */
export function useCreateBoardMutation(baseOptions?: Apollo.MutationHookOptions<CreateBoardMutation, CreateBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBoardMutation, CreateBoardMutationVariables>(CreateBoardDocument, options);
      }
export type CreateBoardMutationHookResult = ReturnType<typeof useCreateBoardMutation>;
export type CreateBoardMutationResult = Apollo.MutationResult<CreateBoardMutation>;
export type CreateBoardMutationOptions = Apollo.BaseMutationOptions<CreateBoardMutation, CreateBoardMutationVariables>;
export const EditBoardDocument = gql`
    mutation editBoard($boardUpdateInput: BoardUpdateInput!, $files: [Upload!], $froalaImages: [Upload!]) {
  updateBoard(
    boardUpdateInput: $boardUpdateInput
    files: $files
    froalaImages: $froalaImages
  ) {
    success
  }
}
    `;
export type EditBoardMutationFn = Apollo.MutationFunction<EditBoardMutation, EditBoardMutationVariables>;

/**
 * __useEditBoardMutation__
 *
 * To run a mutation, you first call `useEditBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBoardMutation, { data, loading, error }] = useEditBoardMutation({
 *   variables: {
 *      boardUpdateInput: // value for 'boardUpdateInput'
 *      files: // value for 'files'
 *      froalaImages: // value for 'froalaImages'
 *   },
 * });
 */
export function useEditBoardMutation(baseOptions?: Apollo.MutationHookOptions<EditBoardMutation, EditBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBoardMutation, EditBoardMutationVariables>(EditBoardDocument, options);
      }
export type EditBoardMutationHookResult = ReturnType<typeof useEditBoardMutation>;
export type EditBoardMutationResult = Apollo.MutationResult<EditBoardMutation>;
export type EditBoardMutationOptions = Apollo.BaseMutationOptions<EditBoardMutation, EditBoardMutationVariables>;
export const DeleteBoardDocument = gql`
    mutation deleteBoard($boardDeleteInput: CommonIdInput!) {
  deleteBoard(boardDeleteInput: $boardDeleteInput) {
    success
  }
}
    `;
export type DeleteBoardMutationFn = Apollo.MutationFunction<DeleteBoardMutation, DeleteBoardMutationVariables>;

/**
 * __useDeleteBoardMutation__
 *
 * To run a mutation, you first call `useDeleteBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoardMutation, { data, loading, error }] = useDeleteBoardMutation({
 *   variables: {
 *      boardDeleteInput: // value for 'boardDeleteInput'
 *   },
 * });
 */
export function useDeleteBoardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBoardMutation, DeleteBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBoardMutation, DeleteBoardMutationVariables>(DeleteBoardDocument, options);
      }
export type DeleteBoardMutationHookResult = ReturnType<typeof useDeleteBoardMutation>;
export type DeleteBoardMutationResult = Apollo.MutationResult<DeleteBoardMutation>;
export type DeleteBoardMutationOptions = Apollo.BaseMutationOptions<DeleteBoardMutation, DeleteBoardMutationVariables>;
export const EditorUploadToS3Document = gql`
    mutation editorUploadToS3($uploadS3Input: UploadS3Input!, $file: Upload!) {
  editorUploadToS3(uploadS3Input: $uploadS3Input, file: $file) {
    link
  }
}
    `;
export type EditorUploadToS3MutationFn = Apollo.MutationFunction<EditorUploadToS3Mutation, EditorUploadToS3MutationVariables>;

/**
 * __useEditorUploadToS3Mutation__
 *
 * To run a mutation, you first call `useEditorUploadToS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditorUploadToS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editorUploadToS3Mutation, { data, loading, error }] = useEditorUploadToS3Mutation({
 *   variables: {
 *      uploadS3Input: // value for 'uploadS3Input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useEditorUploadToS3Mutation(baseOptions?: Apollo.MutationHookOptions<EditorUploadToS3Mutation, EditorUploadToS3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditorUploadToS3Mutation, EditorUploadToS3MutationVariables>(EditorUploadToS3Document, options);
      }
export type EditorUploadToS3MutationHookResult = ReturnType<typeof useEditorUploadToS3Mutation>;
export type EditorUploadToS3MutationResult = Apollo.MutationResult<EditorUploadToS3Mutation>;
export type EditorUploadToS3MutationOptions = Apollo.BaseMutationOptions<EditorUploadToS3Mutation, EditorUploadToS3MutationVariables>;
export const EditorDeleteToS3Document = gql`
    mutation editorDeleteToS3($uploadS3Input: UploadS3Input!) {
  editorDeleteToS3(uploadS3Input: $uploadS3Input) {
    link
  }
}
    `;
export type EditorDeleteToS3MutationFn = Apollo.MutationFunction<EditorDeleteToS3Mutation, EditorDeleteToS3MutationVariables>;

/**
 * __useEditorDeleteToS3Mutation__
 *
 * To run a mutation, you first call `useEditorDeleteToS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditorDeleteToS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editorDeleteToS3Mutation, { data, loading, error }] = useEditorDeleteToS3Mutation({
 *   variables: {
 *      uploadS3Input: // value for 'uploadS3Input'
 *   },
 * });
 */
export function useEditorDeleteToS3Mutation(baseOptions?: Apollo.MutationHookOptions<EditorDeleteToS3Mutation, EditorDeleteToS3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditorDeleteToS3Mutation, EditorDeleteToS3MutationVariables>(EditorDeleteToS3Document, options);
      }
export type EditorDeleteToS3MutationHookResult = ReturnType<typeof useEditorDeleteToS3Mutation>;
export type EditorDeleteToS3MutationResult = Apollo.MutationResult<EditorDeleteToS3Mutation>;
export type EditorDeleteToS3MutationOptions = Apollo.BaseMutationOptions<EditorDeleteToS3Mutation, EditorDeleteToS3MutationVariables>;
export const CommusDocument = gql`
    query commus($input: CommusInput!) {
  commus(input: $input) {
    data {
      id
      users {
        id
        email
        username
        selfie
        babyGender
      }
      commuDt
      title
      body
      photo
      reactions
      likeCount
      hide
      reports {
        id
        users {
          id
          username
        }
        reportInfo1
      }
      adminsub {
        id
        commuId
        photo
        likeCount
        subId
        users {
          id
          username
        }
        body
        hide
        reports {
          id
          users {
            id
            username
            selfie
          }
          reportInfo1
        }
      }
      createdAt
      reportsCount
      bedongGroup {
        id
        year
        month
        type
        locale
      }
    }
    count
  }
}
    `;

/**
 * __useCommusQuery__
 *
 * To run a query within a React component, call `useCommusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommusQuery(baseOptions: Apollo.QueryHookOptions<CommusQuery, CommusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommusQuery, CommusQueryVariables>(CommusDocument, options);
      }
export function useCommusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommusQuery, CommusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommusQuery, CommusQueryVariables>(CommusDocument, options);
        }
export type CommusQueryHookResult = ReturnType<typeof useCommusQuery>;
export type CommusLazyQueryHookResult = ReturnType<typeof useCommusLazyQuery>;
export type CommusQueryResult = Apollo.QueryResult<CommusQuery, CommusQueryVariables>;
export const ReportsDocument = gql`
    query reports($input: ReportsInput!) {
  reports(input: $input) {
    data {
      id
      users {
        id
        username
        selfie
      }
      reportInfo1
    }
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportsQuery(baseOptions: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ShowBedongNoticeTypeDocument = gql`
    query showBedongNoticeType {
  showBedongNoticeType {
    data {
      id
      type
      title
      color
    }
  }
}
    `;

/**
 * __useShowBedongNoticeTypeQuery__
 *
 * To run a query within a React component, call `useShowBedongNoticeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBedongNoticeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBedongNoticeTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowBedongNoticeTypeQuery(baseOptions?: Apollo.QueryHookOptions<ShowBedongNoticeTypeQuery, ShowBedongNoticeTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowBedongNoticeTypeQuery, ShowBedongNoticeTypeQueryVariables>(ShowBedongNoticeTypeDocument, options);
      }
export function useShowBedongNoticeTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowBedongNoticeTypeQuery, ShowBedongNoticeTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowBedongNoticeTypeQuery, ShowBedongNoticeTypeQueryVariables>(ShowBedongNoticeTypeDocument, options);
        }
export type ShowBedongNoticeTypeQueryHookResult = ReturnType<typeof useShowBedongNoticeTypeQuery>;
export type ShowBedongNoticeTypeLazyQueryHookResult = ReturnType<typeof useShowBedongNoticeTypeLazyQuery>;
export type ShowBedongNoticeTypeQueryResult = Apollo.QueryResult<ShowBedongNoticeTypeQuery, ShowBedongNoticeTypeQueryVariables>;
export const CommuHideDocument = gql`
    mutation commuHide($input: CommusHideInput!) {
  commuHide(input: $input) {
    success
  }
}
    `;
export type CommuHideMutationFn = Apollo.MutationFunction<CommuHideMutation, CommuHideMutationVariables>;

/**
 * __useCommuHideMutation__
 *
 * To run a mutation, you first call `useCommuHideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommuHideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commuHideMutation, { data, loading, error }] = useCommuHideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuHideMutation(baseOptions?: Apollo.MutationHookOptions<CommuHideMutation, CommuHideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommuHideMutation, CommuHideMutationVariables>(CommuHideDocument, options);
      }
export type CommuHideMutationHookResult = ReturnType<typeof useCommuHideMutation>;
export type CommuHideMutationResult = Apollo.MutationResult<CommuHideMutation>;
export type CommuHideMutationOptions = Apollo.BaseMutationOptions<CommuHideMutation, CommuHideMutationVariables>;
export const CommuReplyDocument = gql`
    mutation commuReply($input: CommuReplyInput!) {
  commuReply(input: $input) {
    success
  }
}
    `;
export type CommuReplyMutationFn = Apollo.MutationFunction<CommuReplyMutation, CommuReplyMutationVariables>;

/**
 * __useCommuReplyMutation__
 *
 * To run a mutation, you first call `useCommuReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommuReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commuReplyMutation, { data, loading, error }] = useCommuReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuReplyMutation(baseOptions?: Apollo.MutationHookOptions<CommuReplyMutation, CommuReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommuReplyMutation, CommuReplyMutationVariables>(CommuReplyDocument, options);
      }
export type CommuReplyMutationHookResult = ReturnType<typeof useCommuReplyMutation>;
export type CommuReplyMutationResult = Apollo.MutationResult<CommuReplyMutation>;
export type CommuReplyMutationOptions = Apollo.BaseMutationOptions<CommuReplyMutation, CommuReplyMutationVariables>;
export const EditCommuDocument = gql`
    mutation editCommu($input: EditCommuInput!, $files: [Upload!]) {
  editCommu(input: $input, files: $files) {
    success
  }
}
    `;
export type EditCommuMutationFn = Apollo.MutationFunction<EditCommuMutation, EditCommuMutationVariables>;

/**
 * __useEditCommuMutation__
 *
 * To run a mutation, you first call `useEditCommuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommuMutation, { data, loading, error }] = useEditCommuMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useEditCommuMutation(baseOptions?: Apollo.MutationHookOptions<EditCommuMutation, EditCommuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCommuMutation, EditCommuMutationVariables>(EditCommuDocument, options);
      }
export type EditCommuMutationHookResult = ReturnType<typeof useEditCommuMutation>;
export type EditCommuMutationResult = Apollo.MutationResult<EditCommuMutation>;
export type EditCommuMutationOptions = Apollo.BaseMutationOptions<EditCommuMutation, EditCommuMutationVariables>;
export const DeleteCommuDocument = gql`
    mutation deleteCommu($input: DeleteCommuInput!) {
  deleteCommu(input: $input) {
    success
  }
}
    `;
export type DeleteCommuMutationFn = Apollo.MutationFunction<DeleteCommuMutation, DeleteCommuMutationVariables>;

/**
 * __useDeleteCommuMutation__
 *
 * To run a mutation, you first call `useDeleteCommuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommuMutation, { data, loading, error }] = useDeleteCommuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommuMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommuMutation, DeleteCommuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommuMutation, DeleteCommuMutationVariables>(DeleteCommuDocument, options);
      }
export type DeleteCommuMutationHookResult = ReturnType<typeof useDeleteCommuMutation>;
export type DeleteCommuMutationResult = Apollo.MutationResult<DeleteCommuMutation>;
export type DeleteCommuMutationOptions = Apollo.BaseMutationOptions<DeleteCommuMutation, DeleteCommuMutationVariables>;
export const EditReplyCommuDocument = gql`
    mutation editReplyCommu($input: EditCommuReplyInput!, $files: [Upload!]) {
  editReplyCommu(input: $input, files: $files) {
    success
  }
}
    `;
export type EditReplyCommuMutationFn = Apollo.MutationFunction<EditReplyCommuMutation, EditReplyCommuMutationVariables>;

/**
 * __useEditReplyCommuMutation__
 *
 * To run a mutation, you first call `useEditReplyCommuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReplyCommuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReplyCommuMutation, { data, loading, error }] = useEditReplyCommuMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useEditReplyCommuMutation(baseOptions?: Apollo.MutationHookOptions<EditReplyCommuMutation, EditReplyCommuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReplyCommuMutation, EditReplyCommuMutationVariables>(EditReplyCommuDocument, options);
      }
export type EditReplyCommuMutationHookResult = ReturnType<typeof useEditReplyCommuMutation>;
export type EditReplyCommuMutationResult = Apollo.MutationResult<EditReplyCommuMutation>;
export type EditReplyCommuMutationOptions = Apollo.BaseMutationOptions<EditReplyCommuMutation, EditReplyCommuMutationVariables>;
export const DeleteReplyCommuDocument = gql`
    mutation deleteReplyCommu($input: DeleteCommuReplyInput!) {
  deleteReplyCommu(input: $input) {
    success
  }
}
    `;
export type DeleteReplyCommuMutationFn = Apollo.MutationFunction<DeleteReplyCommuMutation, DeleteReplyCommuMutationVariables>;

/**
 * __useDeleteReplyCommuMutation__
 *
 * To run a mutation, you first call `useDeleteReplyCommuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplyCommuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplyCommuMutation, { data, loading, error }] = useDeleteReplyCommuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReplyCommuMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReplyCommuMutation, DeleteReplyCommuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReplyCommuMutation, DeleteReplyCommuMutationVariables>(DeleteReplyCommuDocument, options);
      }
export type DeleteReplyCommuMutationHookResult = ReturnType<typeof useDeleteReplyCommuMutation>;
export type DeleteReplyCommuMutationResult = Apollo.MutationResult<DeleteReplyCommuMutation>;
export type DeleteReplyCommuMutationOptions = Apollo.BaseMutationOptions<DeleteReplyCommuMutation, DeleteReplyCommuMutationVariables>;
export const ShowBedongGroupDocument = gql`
    query showBedongGroup {
  showBedongGroup {
    id
    year
    month
    type
    locale
  }
}
    `;

/**
 * __useShowBedongGroupQuery__
 *
 * To run a query within a React component, call `useShowBedongGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBedongGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBedongGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowBedongGroupQuery(baseOptions?: Apollo.QueryHookOptions<ShowBedongGroupQuery, ShowBedongGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowBedongGroupQuery, ShowBedongGroupQueryVariables>(ShowBedongGroupDocument, options);
      }
export function useShowBedongGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowBedongGroupQuery, ShowBedongGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowBedongGroupQuery, ShowBedongGroupQueryVariables>(ShowBedongGroupDocument, options);
        }
export type ShowBedongGroupQueryHookResult = ReturnType<typeof useShowBedongGroupQuery>;
export type ShowBedongGroupLazyQueryHookResult = ReturnType<typeof useShowBedongGroupLazyQuery>;
export type ShowBedongGroupQueryResult = Apollo.QueryResult<ShowBedongGroupQuery, ShowBedongGroupQueryVariables>;
export const NoticeCommusDocument = gql`
    query noticeCommus($input: CommusNoticeInput!) {
  noticeCommus(input: $input) {
    data {
      id
      kind
      userId
      commuDt
      title
      body
      likeCount
      commuNoticeReply {
        id
        subId
        commuId
        photo
        likeCount
        users {
          id
          username
          selfie
        }
        body
        hide
        createdAt
        updatedAt
      }
      bedongNoticeType {
        id
        type
        title
        color
      }
      bedongNoticeGroup {
        bedongGroup {
          id
          year
          month
          type
          locale
        }
      }
      photo
      reactions
      hide
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useNoticeCommusQuery__
 *
 * To run a query within a React component, call `useNoticeCommusQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoticeCommusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoticeCommusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoticeCommusQuery(baseOptions: Apollo.QueryHookOptions<NoticeCommusQuery, NoticeCommusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoticeCommusQuery, NoticeCommusQueryVariables>(NoticeCommusDocument, options);
      }
export function useNoticeCommusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoticeCommusQuery, NoticeCommusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoticeCommusQuery, NoticeCommusQueryVariables>(NoticeCommusDocument, options);
        }
export type NoticeCommusQueryHookResult = ReturnType<typeof useNoticeCommusQuery>;
export type NoticeCommusLazyQueryHookResult = ReturnType<typeof useNoticeCommusLazyQuery>;
export type NoticeCommusQueryResult = Apollo.QueryResult<NoticeCommusQuery, NoticeCommusQueryVariables>;
export const NoticeDocument = gql`
    mutation notice($input: AddCommuNoticeInput!, $files: [Upload!], $froalaImages: [Upload!]) {
  notice(input: $input, files: $files, froalaImages: $froalaImages) {
    success
  }
}
    `;
export type NoticeMutationFn = Apollo.MutationFunction<NoticeMutation, NoticeMutationVariables>;

/**
 * __useNoticeMutation__
 *
 * To run a mutation, you first call `useNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noticeMutation, { data, loading, error }] = useNoticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *      froalaImages: // value for 'froalaImages'
 *   },
 * });
 */
export function useNoticeMutation(baseOptions?: Apollo.MutationHookOptions<NoticeMutation, NoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NoticeMutation, NoticeMutationVariables>(NoticeDocument, options);
      }
export type NoticeMutationHookResult = ReturnType<typeof useNoticeMutation>;
export type NoticeMutationResult = Apollo.MutationResult<NoticeMutation>;
export type NoticeMutationOptions = Apollo.BaseMutationOptions<NoticeMutation, NoticeMutationVariables>;
export const EditNoticeDocument = gql`
    mutation editNotice($input: EditCommuNoticeInput!, $files: [Upload!], $froalaImages: [Upload!]) {
  editNotice(input: $input, files: $files, froalaImages: $froalaImages) {
    success
  }
}
    `;
export type EditNoticeMutationFn = Apollo.MutationFunction<EditNoticeMutation, EditNoticeMutationVariables>;

/**
 * __useEditNoticeMutation__
 *
 * To run a mutation, you first call `useEditNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoticeMutation, { data, loading, error }] = useEditNoticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *      froalaImages: // value for 'froalaImages'
 *   },
 * });
 */
export function useEditNoticeMutation(baseOptions?: Apollo.MutationHookOptions<EditNoticeMutation, EditNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditNoticeMutation, EditNoticeMutationVariables>(EditNoticeDocument, options);
      }
export type EditNoticeMutationHookResult = ReturnType<typeof useEditNoticeMutation>;
export type EditNoticeMutationResult = Apollo.MutationResult<EditNoticeMutation>;
export type EditNoticeMutationOptions = Apollo.BaseMutationOptions<EditNoticeMutation, EditNoticeMutationVariables>;
export const EditReplyCommuNoticeDocument = gql`
    mutation editReplyCommuNotice($input: EditCommuReplyInput!) {
  editReplyCommuNotice(input: $input) {
    success
  }
}
    `;
export type EditReplyCommuNoticeMutationFn = Apollo.MutationFunction<EditReplyCommuNoticeMutation, EditReplyCommuNoticeMutationVariables>;

/**
 * __useEditReplyCommuNoticeMutation__
 *
 * To run a mutation, you first call `useEditReplyCommuNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReplyCommuNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReplyCommuNoticeMutation, { data, loading, error }] = useEditReplyCommuNoticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReplyCommuNoticeMutation(baseOptions?: Apollo.MutationHookOptions<EditReplyCommuNoticeMutation, EditReplyCommuNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReplyCommuNoticeMutation, EditReplyCommuNoticeMutationVariables>(EditReplyCommuNoticeDocument, options);
      }
export type EditReplyCommuNoticeMutationHookResult = ReturnType<typeof useEditReplyCommuNoticeMutation>;
export type EditReplyCommuNoticeMutationResult = Apollo.MutationResult<EditReplyCommuNoticeMutation>;
export type EditReplyCommuNoticeMutationOptions = Apollo.BaseMutationOptions<EditReplyCommuNoticeMutation, EditReplyCommuNoticeMutationVariables>;
export const CommuNoticeReplyDocument = gql`
    mutation commuNoticeReply($input: CommuReplyInput!) {
  commuNoticeReply(input: $input) {
    success
  }
}
    `;
export type CommuNoticeReplyMutationFn = Apollo.MutationFunction<CommuNoticeReplyMutation, CommuNoticeReplyMutationVariables>;

/**
 * __useCommuNoticeReplyMutation__
 *
 * To run a mutation, you first call `useCommuNoticeReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommuNoticeReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commuNoticeReplyMutation, { data, loading, error }] = useCommuNoticeReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuNoticeReplyMutation(baseOptions?: Apollo.MutationHookOptions<CommuNoticeReplyMutation, CommuNoticeReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommuNoticeReplyMutation, CommuNoticeReplyMutationVariables>(CommuNoticeReplyDocument, options);
      }
export type CommuNoticeReplyMutationHookResult = ReturnType<typeof useCommuNoticeReplyMutation>;
export type CommuNoticeReplyMutationResult = Apollo.MutationResult<CommuNoticeReplyMutation>;
export type CommuNoticeReplyMutationOptions = Apollo.BaseMutationOptions<CommuNoticeReplyMutation, CommuNoticeReplyMutationVariables>;
export const DeleteNoticeDocument = gql`
    mutation deleteNotice($input: DeleteCommuInput!) {
  deleteNotice(input: $input) {
    success
  }
}
    `;
export type DeleteNoticeMutationFn = Apollo.MutationFunction<DeleteNoticeMutation, DeleteNoticeMutationVariables>;

/**
 * __useDeleteNoticeMutation__
 *
 * To run a mutation, you first call `useDeleteNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoticeMutation, { data, loading, error }] = useDeleteNoticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoticeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoticeMutation, DeleteNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoticeMutation, DeleteNoticeMutationVariables>(DeleteNoticeDocument, options);
      }
export type DeleteNoticeMutationHookResult = ReturnType<typeof useDeleteNoticeMutation>;
export type DeleteNoticeMutationResult = Apollo.MutationResult<DeleteNoticeMutation>;
export type DeleteNoticeMutationOptions = Apollo.BaseMutationOptions<DeleteNoticeMutation, DeleteNoticeMutationVariables>;
export const DeleteReplyCommuNoticeDocument = gql`
    mutation deleteReplyCommuNotice($input: DeleteCommuReplyInput!) {
  deleteReplyCommuNotice(input: $input) {
    success
  }
}
    `;
export type DeleteReplyCommuNoticeMutationFn = Apollo.MutationFunction<DeleteReplyCommuNoticeMutation, DeleteReplyCommuNoticeMutationVariables>;

/**
 * __useDeleteReplyCommuNoticeMutation__
 *
 * To run a mutation, you first call `useDeleteReplyCommuNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplyCommuNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplyCommuNoticeMutation, { data, loading, error }] = useDeleteReplyCommuNoticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReplyCommuNoticeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReplyCommuNoticeMutation, DeleteReplyCommuNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReplyCommuNoticeMutation, DeleteReplyCommuNoticeMutationVariables>(DeleteReplyCommuNoticeDocument, options);
      }
export type DeleteReplyCommuNoticeMutationHookResult = ReturnType<typeof useDeleteReplyCommuNoticeMutation>;
export type DeleteReplyCommuNoticeMutationResult = Apollo.MutationResult<DeleteReplyCommuNoticeMutation>;
export type DeleteReplyCommuNoticeMutationOptions = Apollo.BaseMutationOptions<DeleteReplyCommuNoticeMutation, DeleteReplyCommuNoticeMutationVariables>;
export const CommuReportsDocument = gql`
    query commuReports($input: CommuReportsInput!) {
  commuReports(input: $input) {
    data {
      id
      commuOrSubId
      reportInfo1
      createdAt
      users {
        id
        username
        commuDt
        selfie
      }
      commus {
        id
        title
        body
        reactions
        hide
        photo
        users {
          id
          selfie
          username
          commuDt
          createdAt
        }
      }
      commusSub {
        id
        body
        hide
        users {
          id
          selfie
          username
          commuDt
          createdAt
        }
      }
    }
    count
  }
}
    `;

/**
 * __useCommuReportsQuery__
 *
 * To run a query within a React component, call `useCommuReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommuReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommuReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuReportsQuery(baseOptions: Apollo.QueryHookOptions<CommuReportsQuery, CommuReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommuReportsQuery, CommuReportsQueryVariables>(CommuReportsDocument, options);
      }
export function useCommuReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommuReportsQuery, CommuReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommuReportsQuery, CommuReportsQueryVariables>(CommuReportsDocument, options);
        }
export type CommuReportsQueryHookResult = ReturnType<typeof useCommuReportsQuery>;
export type CommuReportsLazyQueryHookResult = ReturnType<typeof useCommuReportsLazyQuery>;
export type CommuReportsQueryResult = Apollo.QueryResult<CommuReportsQuery, CommuReportsQueryVariables>;
export const BedongReportsDocument = gql`
    query bedongReports($input: BedongReportsInput!) {
  bedongReports(input: $input) {
    data {
      id
      type
      description
      createdAt
      user {
        id
        selfie
        username
        createdAt
      }
      bedongPost {
        id
        title
        body
        reactions
        hide
        photo
        commuDt
        users {
          id
          selfie
          username
          commuDt
          createdAt
        }
      }
      bedongPostComment {
        id
        body
        hide
        photo
        likeCount
        userId
        commus {
          id
          title
          body
          reactions
          hide
          photo
          commuDt
        }
      }
      bedongNoticeComment {
        id
        body
        hide
        userId
        commuNotice {
          id
          title
          body
          reactions
          hide
          photo
          commuDt
        }
      }
    }
    count
  }
}
    `;

/**
 * __useBedongReportsQuery__
 *
 * To run a query within a React component, call `useBedongReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBedongReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBedongReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBedongReportsQuery(baseOptions: Apollo.QueryHookOptions<BedongReportsQuery, BedongReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BedongReportsQuery, BedongReportsQueryVariables>(BedongReportsDocument, options);
      }
export function useBedongReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BedongReportsQuery, BedongReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BedongReportsQuery, BedongReportsQueryVariables>(BedongReportsDocument, options);
        }
export type BedongReportsQueryHookResult = ReturnType<typeof useBedongReportsQuery>;
export type BedongReportsLazyQueryHookResult = ReturnType<typeof useBedongReportsLazyQuery>;
export type BedongReportsQueryResult = Apollo.QueryResult<BedongReportsQuery, BedongReportsQueryVariables>;
export const CommuUserReportsDocument = gql`
    query commuUserReports($input: CommuUserBlockRepostInput!) {
  commuUserReports(input: $input) {
    data {
      id
      userId
      user {
        id
        username
        commuDt
        selfie
      }
      reportInfo1
      reportInfo2
      target {
        id
        username
        commuDt
        selfie
      }
      createdAt
    }
    count
  }
}
    `;

/**
 * __useCommuUserReportsQuery__
 *
 * To run a query within a React component, call `useCommuUserReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommuUserReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommuUserReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuUserReportsQuery(baseOptions: Apollo.QueryHookOptions<CommuUserReportsQuery, CommuUserReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommuUserReportsQuery, CommuUserReportsQueryVariables>(CommuUserReportsDocument, options);
      }
export function useCommuUserReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommuUserReportsQuery, CommuUserReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommuUserReportsQuery, CommuUserReportsQueryVariables>(CommuUserReportsDocument, options);
        }
export type CommuUserReportsQueryHookResult = ReturnType<typeof useCommuUserReportsQuery>;
export type CommuUserReportsLazyQueryHookResult = ReturnType<typeof useCommuUserReportsLazyQuery>;
export type CommuUserReportsQueryResult = Apollo.QueryResult<CommuUserReportsQuery, CommuUserReportsQueryVariables>;
export const CommuUserBlocksDocument = gql`
    query commuUserBlocks($input: CommuUserBlockRepostInput!) {
  commuUserBlocks(input: $input) {
    data {
      id
      user {
        id
        username
        commuDt
        selfie
      }
      target {
        id
        username
        commuDt
        selfie
      }
      createdAt
    }
    count
  }
}
    `;

/**
 * __useCommuUserBlocksQuery__
 *
 * To run a query within a React component, call `useCommuUserBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommuUserBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommuUserBlocksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuUserBlocksQuery(baseOptions: Apollo.QueryHookOptions<CommuUserBlocksQuery, CommuUserBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommuUserBlocksQuery, CommuUserBlocksQueryVariables>(CommuUserBlocksDocument, options);
      }
export function useCommuUserBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommuUserBlocksQuery, CommuUserBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommuUserBlocksQuery, CommuUserBlocksQueryVariables>(CommuUserBlocksDocument, options);
        }
export type CommuUserBlocksQueryHookResult = ReturnType<typeof useCommuUserBlocksQuery>;
export type CommuUserBlocksLazyQueryHookResult = ReturnType<typeof useCommuUserBlocksLazyQuery>;
export type CommuUserBlocksQueryResult = Apollo.QueryResult<CommuUserBlocksQuery, CommuUserBlocksQueryVariables>;
export const ShowBedongNoticeGroupDocument = gql`
    query showBedongNoticeGroup {
  showBedongNoticeGroup {
    data {
      id
      year
      month
      type
      locale
      createdAt
      updatedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useShowBedongNoticeGroupQuery__
 *
 * To run a query within a React component, call `useShowBedongNoticeGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBedongNoticeGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBedongNoticeGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowBedongNoticeGroupQuery(baseOptions?: Apollo.QueryHookOptions<ShowBedongNoticeGroupQuery, ShowBedongNoticeGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowBedongNoticeGroupQuery, ShowBedongNoticeGroupQueryVariables>(ShowBedongNoticeGroupDocument, options);
      }
export function useShowBedongNoticeGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowBedongNoticeGroupQuery, ShowBedongNoticeGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowBedongNoticeGroupQuery, ShowBedongNoticeGroupQueryVariables>(ShowBedongNoticeGroupDocument, options);
        }
export type ShowBedongNoticeGroupQueryHookResult = ReturnType<typeof useShowBedongNoticeGroupQuery>;
export type ShowBedongNoticeGroupLazyQueryHookResult = ReturnType<typeof useShowBedongNoticeGroupLazyQuery>;
export type ShowBedongNoticeGroupQueryResult = Apollo.QueryResult<ShowBedongNoticeGroupQuery, ShowBedongNoticeGroupQueryVariables>;
export const CommuAiAbusesDocument = gql`
    query commuAiAbuses($input: CommuAbuseInput!) {
  commuAiAbuses(input: $input) {
    data {
      id
      commuId
      title
      body
      createdAt
      updatedAt
      commus {
        ...BedongPostDetail
      }
    }
    count
  }
}
    ${BedongPostDetailFragmentDoc}`;

/**
 * __useCommuAiAbusesQuery__
 *
 * To run a query within a React component, call `useCommuAiAbusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommuAiAbusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommuAiAbusesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuAiAbusesQuery(baseOptions: Apollo.QueryHookOptions<CommuAiAbusesQuery, CommuAiAbusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommuAiAbusesQuery, CommuAiAbusesQueryVariables>(CommuAiAbusesDocument, options);
      }
export function useCommuAiAbusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommuAiAbusesQuery, CommuAiAbusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommuAiAbusesQuery, CommuAiAbusesQueryVariables>(CommuAiAbusesDocument, options);
        }
export type CommuAiAbusesQueryHookResult = ReturnType<typeof useCommuAiAbusesQuery>;
export type CommuAiAbusesLazyQueryHookResult = ReturnType<typeof useCommuAiAbusesLazyQuery>;
export type CommuAiAbusesQueryResult = Apollo.QueryResult<CommuAiAbusesQuery, CommuAiAbusesQueryVariables>;
export const CommuAiAbusesToggleDocument = gql`
    mutation commuAiAbusesToggle($input: AbuseAiInput!) {
  commuAiAbusesToggle(input: $input) {
    success
  }
}
    `;
export type CommuAiAbusesToggleMutationFn = Apollo.MutationFunction<CommuAiAbusesToggleMutation, CommuAiAbusesToggleMutationVariables>;

/**
 * __useCommuAiAbusesToggleMutation__
 *
 * To run a mutation, you first call `useCommuAiAbusesToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommuAiAbusesToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commuAiAbusesToggleMutation, { data, loading, error }] = useCommuAiAbusesToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuAiAbusesToggleMutation(baseOptions?: Apollo.MutationHookOptions<CommuAiAbusesToggleMutation, CommuAiAbusesToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommuAiAbusesToggleMutation, CommuAiAbusesToggleMutationVariables>(CommuAiAbusesToggleDocument, options);
      }
export type CommuAiAbusesToggleMutationHookResult = ReturnType<typeof useCommuAiAbusesToggleMutation>;
export type CommuAiAbusesToggleMutationResult = Apollo.MutationResult<CommuAiAbusesToggleMutation>;
export type CommuAiAbusesToggleMutationOptions = Apollo.BaseMutationOptions<CommuAiAbusesToggleMutation, CommuAiAbusesToggleMutationVariables>;
export const CommuReplyAiAbusesDocument = gql`
    query commuReplyAiAbuses($input: CommuReplyAbuseInput!) {
  commuReplyAiAbuses(input: $input) {
    data {
      id
      replyId
      body
      createdAt
      updatedAt
      commusReply {
        ...CommentDetail
      }
    }
    count
  }
}
    ${CommentDetailFragmentDoc}`;

/**
 * __useCommuReplyAiAbusesQuery__
 *
 * To run a query within a React component, call `useCommuReplyAiAbusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommuReplyAiAbusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommuReplyAiAbusesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuReplyAiAbusesQuery(baseOptions: Apollo.QueryHookOptions<CommuReplyAiAbusesQuery, CommuReplyAiAbusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommuReplyAiAbusesQuery, CommuReplyAiAbusesQueryVariables>(CommuReplyAiAbusesDocument, options);
      }
export function useCommuReplyAiAbusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommuReplyAiAbusesQuery, CommuReplyAiAbusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommuReplyAiAbusesQuery, CommuReplyAiAbusesQueryVariables>(CommuReplyAiAbusesDocument, options);
        }
export type CommuReplyAiAbusesQueryHookResult = ReturnType<typeof useCommuReplyAiAbusesQuery>;
export type CommuReplyAiAbusesLazyQueryHookResult = ReturnType<typeof useCommuReplyAiAbusesLazyQuery>;
export type CommuReplyAiAbusesQueryResult = Apollo.QueryResult<CommuReplyAiAbusesQuery, CommuReplyAiAbusesQueryVariables>;
export const CommuReplyAiAbusesToggleDocument = gql`
    mutation commuReplyAiAbusesToggle($input: AbuseReplyAiInput!) {
  commuReplyAiAbusesToggle(input: $input) {
    success
  }
}
    `;
export type CommuReplyAiAbusesToggleMutationFn = Apollo.MutationFunction<CommuReplyAiAbusesToggleMutation, CommuReplyAiAbusesToggleMutationVariables>;

/**
 * __useCommuReplyAiAbusesToggleMutation__
 *
 * To run a mutation, you first call `useCommuReplyAiAbusesToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommuReplyAiAbusesToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commuReplyAiAbusesToggleMutation, { data, loading, error }] = useCommuReplyAiAbusesToggleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommuReplyAiAbusesToggleMutation(baseOptions?: Apollo.MutationHookOptions<CommuReplyAiAbusesToggleMutation, CommuReplyAiAbusesToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommuReplyAiAbusesToggleMutation, CommuReplyAiAbusesToggleMutationVariables>(CommuReplyAiAbusesToggleDocument, options);
      }
export type CommuReplyAiAbusesToggleMutationHookResult = ReturnType<typeof useCommuReplyAiAbusesToggleMutation>;
export type CommuReplyAiAbusesToggleMutationResult = Apollo.MutationResult<CommuReplyAiAbusesToggleMutation>;
export type CommuReplyAiAbusesToggleMutationOptions = Apollo.BaseMutationOptions<CommuReplyAiAbusesToggleMutation, CommuReplyAiAbusesToggleMutationVariables>;
export const CreateRandomCouponDocument = gql`
    mutation createRandomCoupon($input: RandomCouponInput!) {
  createRandomCoupon(input: $input) {
    success
  }
}
    `;
export type CreateRandomCouponMutationFn = Apollo.MutationFunction<CreateRandomCouponMutation, CreateRandomCouponMutationVariables>;

/**
 * __useCreateRandomCouponMutation__
 *
 * To run a mutation, you first call `useCreateRandomCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRandomCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRandomCouponMutation, { data, loading, error }] = useCreateRandomCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRandomCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateRandomCouponMutation, CreateRandomCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRandomCouponMutation, CreateRandomCouponMutationVariables>(CreateRandomCouponDocument, options);
      }
export type CreateRandomCouponMutationHookResult = ReturnType<typeof useCreateRandomCouponMutation>;
export type CreateRandomCouponMutationResult = Apollo.MutationResult<CreateRandomCouponMutation>;
export type CreateRandomCouponMutationOptions = Apollo.BaseMutationOptions<CreateRandomCouponMutation, CreateRandomCouponMutationVariables>;
export const DeleteRandomCouponDocument = gql`
    mutation deleteRandomCoupon($input: CommonIdInput!) {
  deleteRandomCoupon(input: $input) {
    success
  }
}
    `;
export type DeleteRandomCouponMutationFn = Apollo.MutationFunction<DeleteRandomCouponMutation, DeleteRandomCouponMutationVariables>;

/**
 * __useDeleteRandomCouponMutation__
 *
 * To run a mutation, you first call `useDeleteRandomCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRandomCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRandomCouponMutation, { data, loading, error }] = useDeleteRandomCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRandomCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRandomCouponMutation, DeleteRandomCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRandomCouponMutation, DeleteRandomCouponMutationVariables>(DeleteRandomCouponDocument, options);
      }
export type DeleteRandomCouponMutationHookResult = ReturnType<typeof useDeleteRandomCouponMutation>;
export type DeleteRandomCouponMutationResult = Apollo.MutationResult<DeleteRandomCouponMutation>;
export type DeleteRandomCouponMutationOptions = Apollo.BaseMutationOptions<DeleteRandomCouponMutation, DeleteRandomCouponMutationVariables>;
export const EventsDocument = gql`
    query events($input: EventsInput!) {
  events(input: $input) {
    data {
      id
      start
      end
      title
      content
      type
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($input: EventInput!, $froalaImages: [Upload!]) {
  createEvent(input: $input, froalaImages: $froalaImages) {
    success
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      froalaImages: // value for 'froalaImages'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($input: EventUpdateInput!, $froalaImages: [Upload!]) {
  updateEvent(input: $input, froalaImages: $froalaImages) {
    success
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      froalaImages: // value for 'froalaImages'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($input: CommonIdInput!) {
  deleteEvent(input: $input) {
    success
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const GetEventByIdDocument = gql`
    query getEventById($input: CommonIdInput!) {
  getEventById(input: $input) {
    data {
      id
      title
      content
      type
      start
      end
    }
  }
}
    `;

/**
 * __useGetEventByIdQuery__
 *
 * To run a query within a React component, call `useGetEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(GetEventByIdDocument, options);
      }
export function useGetEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventByIdQuery, GetEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(GetEventByIdDocument, options);
        }
export type GetEventByIdQueryHookResult = ReturnType<typeof useGetEventByIdQuery>;
export type GetEventByIdLazyQueryHookResult = ReturnType<typeof useGetEventByIdLazyQuery>;
export type GetEventByIdQueryResult = Apollo.QueryResult<GetEventByIdQuery, GetEventByIdQueryVariables>;
export const GetRandomCouponByIdDocument = gql`
    query getRandomCouponById($input: CommonIdInput!) {
  getRandomCouponById(input: $input) {
    data {
      id
      title
      startDate
      endDate
      description
      couponArray {
        couponCd
        quantity
      }
      notes
    }
  }
}
    `;

/**
 * __useGetRandomCouponByIdQuery__
 *
 * To run a query within a React component, call `useGetRandomCouponByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomCouponByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomCouponByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRandomCouponByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRandomCouponByIdQuery, GetRandomCouponByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRandomCouponByIdQuery, GetRandomCouponByIdQueryVariables>(GetRandomCouponByIdDocument, options);
      }
export function useGetRandomCouponByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRandomCouponByIdQuery, GetRandomCouponByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRandomCouponByIdQuery, GetRandomCouponByIdQueryVariables>(GetRandomCouponByIdDocument, options);
        }
export type GetRandomCouponByIdQueryHookResult = ReturnType<typeof useGetRandomCouponByIdQuery>;
export type GetRandomCouponByIdLazyQueryHookResult = ReturnType<typeof useGetRandomCouponByIdLazyQuery>;
export type GetRandomCouponByIdQueryResult = Apollo.QueryResult<GetRandomCouponByIdQuery, GetRandomCouponByIdQueryVariables>;
export const HideAllSeasonalTagDocument = gql`
    mutation hideAllSeasonalTag($input: PostArrayInput!) {
  hideAllSeasonalTag(input: $input) {
    success
  }
}
    `;
export type HideAllSeasonalTagMutationFn = Apollo.MutationFunction<HideAllSeasonalTagMutation, HideAllSeasonalTagMutationVariables>;

/**
 * __useHideAllSeasonalTagMutation__
 *
 * To run a mutation, you first call `useHideAllSeasonalTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideAllSeasonalTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideAllSeasonalTagMutation, { data, loading, error }] = useHideAllSeasonalTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHideAllSeasonalTagMutation(baseOptions?: Apollo.MutationHookOptions<HideAllSeasonalTagMutation, HideAllSeasonalTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideAllSeasonalTagMutation, HideAllSeasonalTagMutationVariables>(HideAllSeasonalTagDocument, options);
      }
export type HideAllSeasonalTagMutationHookResult = ReturnType<typeof useHideAllSeasonalTagMutation>;
export type HideAllSeasonalTagMutationResult = Apollo.MutationResult<HideAllSeasonalTagMutation>;
export type HideAllSeasonalTagMutationOptions = Apollo.BaseMutationOptions<HideAllSeasonalTagMutation, HideAllSeasonalTagMutationVariables>;
export const HideSeasonalTagDocument = gql`
    mutation hideSeasonalTag($input: CommonIdInput!) {
  hideSeasonalTag(input: $input) {
    success
  }
}
    `;
export type HideSeasonalTagMutationFn = Apollo.MutationFunction<HideSeasonalTagMutation, HideSeasonalTagMutationVariables>;

/**
 * __useHideSeasonalTagMutation__
 *
 * To run a mutation, you first call `useHideSeasonalTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideSeasonalTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideSeasonalTagMutation, { data, loading, error }] = useHideSeasonalTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHideSeasonalTagMutation(baseOptions?: Apollo.MutationHookOptions<HideSeasonalTagMutation, HideSeasonalTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideSeasonalTagMutation, HideSeasonalTagMutationVariables>(HideSeasonalTagDocument, options);
      }
export type HideSeasonalTagMutationHookResult = ReturnType<typeof useHideSeasonalTagMutation>;
export type HideSeasonalTagMutationResult = Apollo.MutationResult<HideSeasonalTagMutation>;
export type HideSeasonalTagMutationOptions = Apollo.BaseMutationOptions<HideSeasonalTagMutation, HideSeasonalTagMutationVariables>;
export const ImageUploadDocument = gql`
    mutation imageUpload($files: [Upload!]) {
  imageUpload(files: $files) {
    filename
    mimetype
    encoding
    fieldName
    uploadImageLink
  }
}
    `;
export type ImageUploadMutationFn = Apollo.MutationFunction<ImageUploadMutation, ImageUploadMutationVariables>;

/**
 * __useImageUploadMutation__
 *
 * To run a mutation, you first call `useImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageUploadMutation, { data, loading, error }] = useImageUploadMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useImageUploadMutation(baseOptions?: Apollo.MutationHookOptions<ImageUploadMutation, ImageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageUploadMutation, ImageUploadMutationVariables>(ImageUploadDocument, options);
      }
export type ImageUploadMutationHookResult = ReturnType<typeof useImageUploadMutation>;
export type ImageUploadMutationResult = Apollo.MutationResult<ImageUploadMutation>;
export type ImageUploadMutationOptions = Apollo.BaseMutationOptions<ImageUploadMutation, ImageUploadMutationVariables>;
export const GetMarketingEventDocument = gql`
    query getMarketingEvent($input: GetMarketingEventInput!) {
  getMarketingEvent(input: $input) {
    data {
      id
      eventType
      eventName
      start
      end
      exposedScreen {
        ...LabelValue
      }
      exposedTargetGroup {
        ...LabelValue
      }
      marketingEventImages {
        ...MarketingEventImage
      }
      createdAt
      updatedAt
      landingScreenUrl
      period {
        start
        end
      }
      dialogTitle
      dialogContent
      backgroundColor
    }
    count
  }
}
    ${LabelValueFragmentDoc}
${MarketingEventImageFragmentDoc}`;

/**
 * __useGetMarketingEventQuery__
 *
 * To run a query within a React component, call `useGetMarketingEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingEventQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMarketingEventQuery(baseOptions: Apollo.QueryHookOptions<GetMarketingEventQuery, GetMarketingEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketingEventQuery, GetMarketingEventQueryVariables>(GetMarketingEventDocument, options);
      }
export function useGetMarketingEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketingEventQuery, GetMarketingEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketingEventQuery, GetMarketingEventQueryVariables>(GetMarketingEventDocument, options);
        }
export type GetMarketingEventQueryHookResult = ReturnType<typeof useGetMarketingEventQuery>;
export type GetMarketingEventLazyQueryHookResult = ReturnType<typeof useGetMarketingEventLazyQuery>;
export type GetMarketingEventQueryResult = Apollo.QueryResult<GetMarketingEventQuery, GetMarketingEventQueryVariables>;
export const GetMarketingEventInputLabelDocument = gql`
    query getMarketingEventInputLabel {
  getMarketingEventInputLabel {
    eventType {
      ...Input
    }
    exposedScreen {
      ...Input
    }
    exposedTargetGroup {
      ...Input
    }
  }
}
    ${InputFragmentDoc}`;

/**
 * __useGetMarketingEventInputLabelQuery__
 *
 * To run a query within a React component, call `useGetMarketingEventInputLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingEventInputLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingEventInputLabelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketingEventInputLabelQuery(baseOptions?: Apollo.QueryHookOptions<GetMarketingEventInputLabelQuery, GetMarketingEventInputLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketingEventInputLabelQuery, GetMarketingEventInputLabelQueryVariables>(GetMarketingEventInputLabelDocument, options);
      }
export function useGetMarketingEventInputLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketingEventInputLabelQuery, GetMarketingEventInputLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketingEventInputLabelQuery, GetMarketingEventInputLabelQueryVariables>(GetMarketingEventInputLabelDocument, options);
        }
export type GetMarketingEventInputLabelQueryHookResult = ReturnType<typeof useGetMarketingEventInputLabelQuery>;
export type GetMarketingEventInputLabelLazyQueryHookResult = ReturnType<typeof useGetMarketingEventInputLabelLazyQuery>;
export type GetMarketingEventInputLabelQueryResult = Apollo.QueryResult<GetMarketingEventInputLabelQuery, GetMarketingEventInputLabelQueryVariables>;
export const CreateMarketingEventDocument = gql`
    mutation createMarketingEvent($createInput: CreateMarketingEventInput!, $files: [Upload!]) {
  createMarketingEvent(createInput: $createInput, files: $files) {
    success
  }
}
    `;
export type CreateMarketingEventMutationFn = Apollo.MutationFunction<CreateMarketingEventMutation, CreateMarketingEventMutationVariables>;

/**
 * __useCreateMarketingEventMutation__
 *
 * To run a mutation, you first call `useCreateMarketingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketingEventMutation, { data, loading, error }] = useCreateMarketingEventMutation({
 *   variables: {
 *      createInput: // value for 'createInput'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateMarketingEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketingEventMutation, CreateMarketingEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketingEventMutation, CreateMarketingEventMutationVariables>(CreateMarketingEventDocument, options);
      }
export type CreateMarketingEventMutationHookResult = ReturnType<typeof useCreateMarketingEventMutation>;
export type CreateMarketingEventMutationResult = Apollo.MutationResult<CreateMarketingEventMutation>;
export type CreateMarketingEventMutationOptions = Apollo.BaseMutationOptions<CreateMarketingEventMutation, CreateMarketingEventMutationVariables>;
export const UpdateMarketingEventInputDocument = gql`
    mutation updateMarketingEventInput($updateInput: UpdateMarketingEventInput!, $files: [Upload!]) {
  updateMarketingEvent(updateInput: $updateInput, files: $files) {
    success
  }
}
    `;
export type UpdateMarketingEventInputMutationFn = Apollo.MutationFunction<UpdateMarketingEventInputMutation, UpdateMarketingEventInputMutationVariables>;

/**
 * __useUpdateMarketingEventInputMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingEventInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingEventInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingEventInputMutation, { data, loading, error }] = useUpdateMarketingEventInputMutation({
 *   variables: {
 *      updateInput: // value for 'updateInput'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateMarketingEventInputMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketingEventInputMutation, UpdateMarketingEventInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketingEventInputMutation, UpdateMarketingEventInputMutationVariables>(UpdateMarketingEventInputDocument, options);
      }
export type UpdateMarketingEventInputMutationHookResult = ReturnType<typeof useUpdateMarketingEventInputMutation>;
export type UpdateMarketingEventInputMutationResult = Apollo.MutationResult<UpdateMarketingEventInputMutation>;
export type UpdateMarketingEventInputMutationOptions = Apollo.BaseMutationOptions<UpdateMarketingEventInputMutation, UpdateMarketingEventInputMutationVariables>;
export const DeleteMarketingEventDocument = gql`
    mutation deleteMarketingEvent($marketingEventDeleteInput: CommonIdInput!) {
  deleteMarketingEvent(marketingEventDeleteInput: $marketingEventDeleteInput) {
    success
  }
}
    `;
export type DeleteMarketingEventMutationFn = Apollo.MutationFunction<DeleteMarketingEventMutation, DeleteMarketingEventMutationVariables>;

/**
 * __useDeleteMarketingEventMutation__
 *
 * To run a mutation, you first call `useDeleteMarketingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarketingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarketingEventMutation, { data, loading, error }] = useDeleteMarketingEventMutation({
 *   variables: {
 *      marketingEventDeleteInput: // value for 'marketingEventDeleteInput'
 *   },
 * });
 */
export function useDeleteMarketingEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMarketingEventMutation, DeleteMarketingEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMarketingEventMutation, DeleteMarketingEventMutationVariables>(DeleteMarketingEventDocument, options);
      }
export type DeleteMarketingEventMutationHookResult = ReturnType<typeof useDeleteMarketingEventMutation>;
export type DeleteMarketingEventMutationResult = Apollo.MutationResult<DeleteMarketingEventMutation>;
export type DeleteMarketingEventMutationOptions = Apollo.BaseMutationOptions<DeleteMarketingEventMutation, DeleteMarketingEventMutationVariables>;
export const NcpInfoDocument = gql`
    query ncpInfo($userId: Float!) {
  ncpInfo(userId: $userId)
}
    `;

/**
 * __useNcpInfoQuery__
 *
 * To run a query within a React component, call `useNcpInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNcpInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNcpInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNcpInfoQuery(baseOptions: Apollo.QueryHookOptions<NcpInfoQuery, NcpInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NcpInfoQuery, NcpInfoQueryVariables>(NcpInfoDocument, options);
      }
export function useNcpInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NcpInfoQuery, NcpInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NcpInfoQuery, NcpInfoQueryVariables>(NcpInfoDocument, options);
        }
export type NcpInfoQueryHookResult = ReturnType<typeof useNcpInfoQuery>;
export type NcpInfoLazyQueryHookResult = ReturnType<typeof useNcpInfoLazyQuery>;
export type NcpInfoQueryResult = Apollo.QueryResult<NcpInfoQuery, NcpInfoQueryVariables>;
export const MobileAuthDocument = gql`
    mutation mobileAuth($type: Float!, $id: Float!, $mobileNumber: String!) {
  mobileAuth(type: $type, id: $id, mobileNumber: $mobileNumber) {
    success
  }
}
    `;
export type MobileAuthMutationFn = Apollo.MutationFunction<MobileAuthMutation, MobileAuthMutationVariables>;

/**
 * __useMobileAuthMutation__
 *
 * To run a mutation, you first call `useMobileAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMobileAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileAuthMutation, { data, loading, error }] = useMobileAuthMutation({
 *   variables: {
 *      type: // value for 'type'
 *      id: // value for 'id'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useMobileAuthMutation(baseOptions?: Apollo.MutationHookOptions<MobileAuthMutation, MobileAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MobileAuthMutation, MobileAuthMutationVariables>(MobileAuthDocument, options);
      }
export type MobileAuthMutationHookResult = ReturnType<typeof useMobileAuthMutation>;
export type MobileAuthMutationResult = Apollo.MutationResult<MobileAuthMutation>;
export type MobileAuthMutationOptions = Apollo.BaseMutationOptions<MobileAuthMutation, MobileAuthMutationVariables>;
export const TagsDocument = gql`
    query tags($input: TagsInput!) {
  tags(input: $input) {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const PostsDocument = gql`
    query posts($input: PostsInput!) {
  posts(input: $input) {
    data {
      id
      title
      heroimage
      content
      author {
        id
        name
        photo
      }
      publish
      tagsName
      tagsId
      views
      bookmarkCount
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostsQuery(baseOptions: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
      }
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const GetPostByIdDocument = gql`
    query getPostById($input: CommonIdInput!) {
  getPostById(input: $input) {
    data {
      id
      title
      heroimage
      content
      author {
        id
        name
        photo
      }
      tags {
        id
        name
      }
      publish
      views
      bookmarkCount
      shares
      createdAt
      updatedAt
    }
    category {
      category_id
    }
  }
}
    `;

/**
 * __useGetPostByIdQuery__
 *
 * To run a query within a React component, call `useGetPostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPostByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPostByIdQuery, GetPostByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostByIdQuery, GetPostByIdQueryVariables>(GetPostByIdDocument, options);
      }
export function useGetPostByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostByIdQuery, GetPostByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostByIdQuery, GetPostByIdQueryVariables>(GetPostByIdDocument, options);
        }
export type GetPostByIdQueryHookResult = ReturnType<typeof useGetPostByIdQuery>;
export type GetPostByIdLazyQueryHookResult = ReturnType<typeof useGetPostByIdLazyQuery>;
export type GetPostByIdQueryResult = Apollo.QueryResult<GetPostByIdQuery, GetPostByIdQueryVariables>;
export const CreatePostDocument = gql`
    mutation createPost($input: CreatePostInput!, $files: [Upload!]) {
  createPost(createInput: $input, files: $files) {
    success
  }
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation updatePost($input: UpdatePostInput!, $files: [Upload!]) {
  updatePost(updatePostInput: $input, files: $files) {
    success
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const DeletePostDocument = gql`
    mutation deletePost($input: CommonIdInput!) {
  deletePost(input: $input) {
    success
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DeletePostTagDocument = gql`
    mutation deletePostTag($input: PostTagInput!) {
  deletePostTag(input: $input) {
    success
  }
}
    `;
export type DeletePostTagMutationFn = Apollo.MutationFunction<DeletePostTagMutation, DeletePostTagMutationVariables>;

/**
 * __useDeletePostTagMutation__
 *
 * To run a mutation, you first call `useDeletePostTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostTagMutation, { data, loading, error }] = useDeletePostTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePostTagMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostTagMutation, DeletePostTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostTagMutation, DeletePostTagMutationVariables>(DeletePostTagDocument, options);
      }
export type DeletePostTagMutationHookResult = ReturnType<typeof useDeletePostTagMutation>;
export type DeletePostTagMutationResult = Apollo.MutationResult<DeletePostTagMutation>;
export type DeletePostTagMutationOptions = Apollo.BaseMutationOptions<DeletePostTagMutation, DeletePostTagMutationVariables>;
export const CreatePushDocument = gql`
    mutation createPush($input: PushInput!, $file: Upload) {
  createPush(input: $input, file: $file) {
    success
  }
}
    `;
export type CreatePushMutationFn = Apollo.MutationFunction<CreatePushMutation, CreatePushMutationVariables>;

/**
 * __useCreatePushMutation__
 *
 * To run a mutation, you first call `useCreatePushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPushMutation, { data, loading, error }] = useCreatePushMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreatePushMutation(baseOptions?: Apollo.MutationHookOptions<CreatePushMutation, CreatePushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePushMutation, CreatePushMutationVariables>(CreatePushDocument, options);
      }
export type CreatePushMutationHookResult = ReturnType<typeof useCreatePushMutation>;
export type CreatePushMutationResult = Apollo.MutationResult<CreatePushMutation>;
export type CreatePushMutationOptions = Apollo.BaseMutationOptions<CreatePushMutation, CreatePushMutationVariables>;
export const TestPushDocument = gql`
    mutation testPush($input: PushInput!, $file: Upload) {
  testPush(input: $input, file: $file) {
    success
  }
}
    `;
export type TestPushMutationFn = Apollo.MutationFunction<TestPushMutation, TestPushMutationVariables>;

/**
 * __useTestPushMutation__
 *
 * To run a mutation, you first call `useTestPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testPushMutation, { data, loading, error }] = useTestPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useTestPushMutation(baseOptions?: Apollo.MutationHookOptions<TestPushMutation, TestPushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestPushMutation, TestPushMutationVariables>(TestPushDocument, options);
      }
export type TestPushMutationHookResult = ReturnType<typeof useTestPushMutation>;
export type TestPushMutationResult = Apollo.MutationResult<TestPushMutation>;
export type TestPushMutationOptions = Apollo.BaseMutationOptions<TestPushMutation, TestPushMutationVariables>;
export const UpdatePushDocument = gql`
    mutation updatePush($input: PushUpdate!, $file: Upload) {
  updatePush(input: $input, file: $file) {
    success
  }
}
    `;
export type UpdatePushMutationFn = Apollo.MutationFunction<UpdatePushMutation, UpdatePushMutationVariables>;

/**
 * __useUpdatePushMutation__
 *
 * To run a mutation, you first call `useUpdatePushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePushMutation, { data, loading, error }] = useUpdatePushMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdatePushMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePushMutation, UpdatePushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePushMutation, UpdatePushMutationVariables>(UpdatePushDocument, options);
      }
export type UpdatePushMutationHookResult = ReturnType<typeof useUpdatePushMutation>;
export type UpdatePushMutationResult = Apollo.MutationResult<UpdatePushMutation>;
export type UpdatePushMutationOptions = Apollo.BaseMutationOptions<UpdatePushMutation, UpdatePushMutationVariables>;
export const DeletePushDocument = gql`
    mutation deletePush($input: CommonIdInput!) {
  deletePush(input: $input) {
    success
  }
}
    `;
export type DeletePushMutationFn = Apollo.MutationFunction<DeletePushMutation, DeletePushMutationVariables>;

/**
 * __useDeletePushMutation__
 *
 * To run a mutation, you first call `useDeletePushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePushMutation, { data, loading, error }] = useDeletePushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePushMutation(baseOptions?: Apollo.MutationHookOptions<DeletePushMutation, DeletePushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePushMutation, DeletePushMutationVariables>(DeletePushDocument, options);
      }
export type DeletePushMutationHookResult = ReturnType<typeof useDeletePushMutation>;
export type DeletePushMutationResult = Apollo.MutationResult<DeletePushMutation>;
export type DeletePushMutationOptions = Apollo.BaseMutationOptions<DeletePushMutation, DeletePushMutationVariables>;
export const GetPushOpenedCountDocument = gql`
    query getPushOpenedCount($input: PushOpenCountInput!) {
  getPushOpenedCount(input: $input) {
    count
  }
}
    `;

/**
 * __useGetPushOpenedCountQuery__
 *
 * To run a query within a React component, call `useGetPushOpenedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushOpenedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushOpenedCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPushOpenedCountQuery(baseOptions: Apollo.QueryHookOptions<GetPushOpenedCountQuery, GetPushOpenedCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPushOpenedCountQuery, GetPushOpenedCountQueryVariables>(GetPushOpenedCountDocument, options);
      }
export function useGetPushOpenedCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPushOpenedCountQuery, GetPushOpenedCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPushOpenedCountQuery, GetPushOpenedCountQueryVariables>(GetPushOpenedCountDocument, options);
        }
export type GetPushOpenedCountQueryHookResult = ReturnType<typeof useGetPushOpenedCountQuery>;
export type GetPushOpenedCountLazyQueryHookResult = ReturnType<typeof useGetPushOpenedCountLazyQuery>;
export type GetPushOpenedCountQueryResult = Apollo.QueryResult<GetPushOpenedCountQuery, GetPushOpenedCountQueryVariables>;
export const GetPushesDocument = gql`
    query getPushes($input: PushesInput!) {
  getPushes(input: $input) {
    data {
      id
      title
      body
      image
      cronExpression
      reservationType
      reservationWeek
      reservationDate
      reservationTime
      isActive
      isSchedule
      createdAt
      updatedAt
      totalSendCount
      sendCount
    }
    count
  }
}
    `;

/**
 * __useGetPushesQuery__
 *
 * To run a query within a React component, call `useGetPushesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPushesQuery(baseOptions: Apollo.QueryHookOptions<GetPushesQuery, GetPushesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPushesQuery, GetPushesQueryVariables>(GetPushesDocument, options);
      }
export function useGetPushesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPushesQuery, GetPushesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPushesQuery, GetPushesQueryVariables>(GetPushesDocument, options);
        }
export type GetPushesQueryHookResult = ReturnType<typeof useGetPushesQuery>;
export type GetPushesLazyQueryHookResult = ReturnType<typeof useGetPushesLazyQuery>;
export type GetPushesQueryResult = Apollo.QueryResult<GetPushesQuery, GetPushesQueryVariables>;
export const GetPushByIdDocument = gql`
    query getPushById($input: CommonIdInput!) {
  getPushById(input: $input) {
    data {
      id
      title
      body
      image
      pushTarget
      cronExpression
      pushData
      reservationType
      reservationDay
      reservationWeek
      reservationTime
      reservationDate
      isActive
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetPushByIdQuery__
 *
 * To run a query within a React component, call `useGetPushByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPushByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPushByIdQuery, GetPushByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPushByIdQuery, GetPushByIdQueryVariables>(GetPushByIdDocument, options);
      }
export function useGetPushByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPushByIdQuery, GetPushByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPushByIdQuery, GetPushByIdQueryVariables>(GetPushByIdDocument, options);
        }
export type GetPushByIdQueryHookResult = ReturnType<typeof useGetPushByIdQuery>;
export type GetPushByIdLazyQueryHookResult = ReturnType<typeof useGetPushByIdLazyQuery>;
export type GetPushByIdQueryResult = Apollo.QueryResult<GetPushByIdQuery, GetPushByIdQueryVariables>;
export const GetBillyPushCountsDocument = gql`
    query getBillyPushCounts($input: PushInput!) {
  getBillyPushCounts(input: $input) {
    count
  }
}
    `;

/**
 * __useGetBillyPushCountsQuery__
 *
 * To run a query within a React component, call `useGetBillyPushCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillyPushCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillyPushCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBillyPushCountsQuery(baseOptions: Apollo.QueryHookOptions<GetBillyPushCountsQuery, GetBillyPushCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillyPushCountsQuery, GetBillyPushCountsQueryVariables>(GetBillyPushCountsDocument, options);
      }
export function useGetBillyPushCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillyPushCountsQuery, GetBillyPushCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillyPushCountsQuery, GetBillyPushCountsQueryVariables>(GetBillyPushCountsDocument, options);
        }
export type GetBillyPushCountsQueryHookResult = ReturnType<typeof useGetBillyPushCountsQuery>;
export type GetBillyPushCountsLazyQueryHookResult = ReturnType<typeof useGetBillyPushCountsLazyQuery>;
export type GetBillyPushCountsQueryResult = Apollo.QueryResult<GetBillyPushCountsQuery, GetBillyPushCountsQueryVariables>;
export const RandomCouponsDocument = gql`
    query randomCoupons($input: EventsInput!) {
  randomCoupons(input: $input) {
    data {
      id
      title
      startDate
      endDate
      description
      createdAt
      updatedAt
      couponIds
    }
    count
  }
}
    `;

/**
 * __useRandomCouponsQuery__
 *
 * To run a query within a React component, call `useRandomCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomCouponsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRandomCouponsQuery(baseOptions: Apollo.QueryHookOptions<RandomCouponsQuery, RandomCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RandomCouponsQuery, RandomCouponsQueryVariables>(RandomCouponsDocument, options);
      }
export function useRandomCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RandomCouponsQuery, RandomCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RandomCouponsQuery, RandomCouponsQueryVariables>(RandomCouponsDocument, options);
        }
export type RandomCouponsQueryHookResult = ReturnType<typeof useRandomCouponsQuery>;
export type RandomCouponsLazyQueryHookResult = ReturnType<typeof useRandomCouponsLazyQuery>;
export type RandomCouponsQueryResult = Apollo.QueryResult<RandomCouponsQuery, RandomCouponsQueryVariables>;
export const ShowAllSeasonalTagDocument = gql`
    mutation showAllSeasonalTag($input: PostArrayInput!) {
  showAllSeasonalTag(input: $input) {
    success
  }
}
    `;
export type ShowAllSeasonalTagMutationFn = Apollo.MutationFunction<ShowAllSeasonalTagMutation, ShowAllSeasonalTagMutationVariables>;

/**
 * __useShowAllSeasonalTagMutation__
 *
 * To run a mutation, you first call `useShowAllSeasonalTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShowAllSeasonalTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showAllSeasonalTagMutation, { data, loading, error }] = useShowAllSeasonalTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShowAllSeasonalTagMutation(baseOptions?: Apollo.MutationHookOptions<ShowAllSeasonalTagMutation, ShowAllSeasonalTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShowAllSeasonalTagMutation, ShowAllSeasonalTagMutationVariables>(ShowAllSeasonalTagDocument, options);
      }
export type ShowAllSeasonalTagMutationHookResult = ReturnType<typeof useShowAllSeasonalTagMutation>;
export type ShowAllSeasonalTagMutationResult = Apollo.MutationResult<ShowAllSeasonalTagMutation>;
export type ShowAllSeasonalTagMutationOptions = Apollo.BaseMutationOptions<ShowAllSeasonalTagMutation, ShowAllSeasonalTagMutationVariables>;
export const ShowSeasonalTagDocument = gql`
    mutation showSeasonalTag($input: CommonIdInput!) {
  showSeasonalTag(input: $input) {
    success
  }
}
    `;
export type ShowSeasonalTagMutationFn = Apollo.MutationFunction<ShowSeasonalTagMutation, ShowSeasonalTagMutationVariables>;

/**
 * __useShowSeasonalTagMutation__
 *
 * To run a mutation, you first call `useShowSeasonalTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShowSeasonalTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showSeasonalTagMutation, { data, loading, error }] = useShowSeasonalTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShowSeasonalTagMutation(baseOptions?: Apollo.MutationHookOptions<ShowSeasonalTagMutation, ShowSeasonalTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShowSeasonalTagMutation, ShowSeasonalTagMutationVariables>(ShowSeasonalTagDocument, options);
      }
export type ShowSeasonalTagMutationHookResult = ReturnType<typeof useShowSeasonalTagMutation>;
export type ShowSeasonalTagMutationResult = Apollo.MutationResult<ShowSeasonalTagMutation>;
export type ShowSeasonalTagMutationOptions = Apollo.BaseMutationOptions<ShowSeasonalTagMutation, ShowSeasonalTagMutationVariables>;
export const SplashesDocument = gql`
    query splashes($input: SplashesInput!) {
  splashes(input: $input) {
    data {
      id
      language
      start
      end
      originalName
      assumedName
      accessPath
      extension
      length
      isDefault
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useSplashesQuery__
 *
 * To run a query within a React component, call `useSplashesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSplashesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSplashesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSplashesQuery(baseOptions: Apollo.QueryHookOptions<SplashesQuery, SplashesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SplashesQuery, SplashesQueryVariables>(SplashesDocument, options);
      }
export function useSplashesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SplashesQuery, SplashesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SplashesQuery, SplashesQueryVariables>(SplashesDocument, options);
        }
export type SplashesQueryHookResult = ReturnType<typeof useSplashesQuery>;
export type SplashesLazyQueryHookResult = ReturnType<typeof useSplashesLazyQuery>;
export type SplashesQueryResult = Apollo.QueryResult<SplashesQuery, SplashesQueryVariables>;
export const GetAppSplashByIdDocument = gql`
    query getAppSplashById($input: CommonIdInput!) {
  getAppSplashById(input: $input) {
    data {
      id
      language
      start
      end
      originalName
      assumedName
      accessPath
      extension
      length
      isDefault
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAppSplashByIdQuery__
 *
 * To run a query within a React component, call `useGetAppSplashByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSplashByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSplashByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAppSplashByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAppSplashByIdQuery, GetAppSplashByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppSplashByIdQuery, GetAppSplashByIdQueryVariables>(GetAppSplashByIdDocument, options);
      }
export function useGetAppSplashByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppSplashByIdQuery, GetAppSplashByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppSplashByIdQuery, GetAppSplashByIdQueryVariables>(GetAppSplashByIdDocument, options);
        }
export type GetAppSplashByIdQueryHookResult = ReturnType<typeof useGetAppSplashByIdQuery>;
export type GetAppSplashByIdLazyQueryHookResult = ReturnType<typeof useGetAppSplashByIdLazyQuery>;
export type GetAppSplashByIdQueryResult = Apollo.QueryResult<GetAppSplashByIdQuery, GetAppSplashByIdQueryVariables>;
export const CreateSplashDocument = gql`
    mutation createSplash($input: CreateSplasheInput!, $file: Upload) {
  createSplash(createSplashInput: $input, file: $file) {
    success
  }
}
    `;
export type CreateSplashMutationFn = Apollo.MutationFunction<CreateSplashMutation, CreateSplashMutationVariables>;

/**
 * __useCreateSplashMutation__
 *
 * To run a mutation, you first call `useCreateSplashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSplashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSplashMutation, { data, loading, error }] = useCreateSplashMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateSplashMutation(baseOptions?: Apollo.MutationHookOptions<CreateSplashMutation, CreateSplashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSplashMutation, CreateSplashMutationVariables>(CreateSplashDocument, options);
      }
export type CreateSplashMutationHookResult = ReturnType<typeof useCreateSplashMutation>;
export type CreateSplashMutationResult = Apollo.MutationResult<CreateSplashMutation>;
export type CreateSplashMutationOptions = Apollo.BaseMutationOptions<CreateSplashMutation, CreateSplashMutationVariables>;
export const UpdateSplashDocument = gql`
    mutation updateSplash($input: UpdateSplasheInput!, $file: Upload) {
  updateSplash(updateInput: $input, file: $file) {
    success
  }
}
    `;
export type UpdateSplashMutationFn = Apollo.MutationFunction<UpdateSplashMutation, UpdateSplashMutationVariables>;

/**
 * __useUpdateSplashMutation__
 *
 * To run a mutation, you first call `useUpdateSplashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSplashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSplashMutation, { data, loading, error }] = useUpdateSplashMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateSplashMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSplashMutation, UpdateSplashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSplashMutation, UpdateSplashMutationVariables>(UpdateSplashDocument, options);
      }
export type UpdateSplashMutationHookResult = ReturnType<typeof useUpdateSplashMutation>;
export type UpdateSplashMutationResult = Apollo.MutationResult<UpdateSplashMutation>;
export type UpdateSplashMutationOptions = Apollo.BaseMutationOptions<UpdateSplashMutation, UpdateSplashMutationVariables>;
export const DeleteSplashDocument = gql`
    mutation deleteSplash($splashDeleteInput: CommonIdInput!) {
  deleteSplash(splashDeleteInput: $splashDeleteInput) {
    success
  }
}
    `;
export type DeleteSplashMutationFn = Apollo.MutationFunction<DeleteSplashMutation, DeleteSplashMutationVariables>;

/**
 * __useDeleteSplashMutation__
 *
 * To run a mutation, you first call `useDeleteSplashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSplashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSplashMutation, { data, loading, error }] = useDeleteSplashMutation({
 *   variables: {
 *      splashDeleteInput: // value for 'splashDeleteInput'
 *   },
 * });
 */
export function useDeleteSplashMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSplashMutation, DeleteSplashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSplashMutation, DeleteSplashMutationVariables>(DeleteSplashDocument, options);
      }
export type DeleteSplashMutationHookResult = ReturnType<typeof useDeleteSplashMutation>;
export type DeleteSplashMutationResult = Apollo.MutationResult<DeleteSplashMutation>;
export type DeleteSplashMutationOptions = Apollo.BaseMutationOptions<DeleteSplashMutation, DeleteSplashMutationVariables>;
export const UpdateRandomCouponDocument = gql`
    mutation updateRandomCoupon($input: RandomCouponUpdateInput!) {
  updateRandomCoupon(input: $input) {
    success
  }
}
    `;
export type UpdateRandomCouponMutationFn = Apollo.MutationFunction<UpdateRandomCouponMutation, UpdateRandomCouponMutationVariables>;

/**
 * __useUpdateRandomCouponMutation__
 *
 * To run a mutation, you first call `useUpdateRandomCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRandomCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRandomCouponMutation, { data, loading, error }] = useUpdateRandomCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRandomCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRandomCouponMutation, UpdateRandomCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRandomCouponMutation, UpdateRandomCouponMutationVariables>(UpdateRandomCouponDocument, options);
      }
export type UpdateRandomCouponMutationHookResult = ReturnType<typeof useUpdateRandomCouponMutation>;
export type UpdateRandomCouponMutationResult = Apollo.MutationResult<UpdateRandomCouponMutation>;
export type UpdateRandomCouponMutationOptions = Apollo.BaseMutationOptions<UpdateRandomCouponMutation, UpdateRandomCouponMutationVariables>;
export const UsersDocument = gql`
    query users($input: UsersInput!) {
  users(input: $input) {
    data {
      id
      username
      email
      photo
      selfie
      babyname
      duedate
      babyGender
      parentGender
      lastLogin
      updatedAt
      createdAt
      emailUse
      blockedAt
      familyMembers {
        relationType
        baby {
          babyname
          duedate
          babyGender
        }
      }
      usersSocials {
        service
      }
      userSettings {
        id
        userId
        eventConfirm
        alarmConfirm
        createdAt
        updatedAt
        chatwbabyConfirm
        commuConfirm
        hotdealConfirm
        ncpInit
      }
      firebaseTokens {
        id
        userId
        fcmToken
      }
    }
    count
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: CommonIdInput!) {
  deleteUser(input: $input) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: PasswordChangeInput!) {
  changePassword(input: $input) {
    success
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ShowExcelDownloadDocument = gql`
    query showExcelDownload {
  showExcelDownload {
    buffer
  }
}
    `;

/**
 * __useShowExcelDownloadQuery__
 *
 * To run a query within a React component, call `useShowExcelDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowExcelDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowExcelDownloadQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowExcelDownloadQuery(baseOptions?: Apollo.QueryHookOptions<ShowExcelDownloadQuery, ShowExcelDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowExcelDownloadQuery, ShowExcelDownloadQueryVariables>(ShowExcelDownloadDocument, options);
      }
export function useShowExcelDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowExcelDownloadQuery, ShowExcelDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowExcelDownloadQuery, ShowExcelDownloadQueryVariables>(ShowExcelDownloadDocument, options);
        }
export type ShowExcelDownloadQueryHookResult = ReturnType<typeof useShowExcelDownloadQuery>;
export type ShowExcelDownloadLazyQueryHookResult = ReturnType<typeof useShowExcelDownloadLazyQuery>;
export type ShowExcelDownloadQueryResult = Apollo.QueryResult<ShowExcelDownloadQuery, ShowExcelDownloadQueryVariables>;
export const BedongBlockUserDocument = gql`
    mutation bedongBlockUser($input: BedongBlockUserInput!) {
  bedongBlockUser(input: $input) {
    success
  }
}
    `;
export type BedongBlockUserMutationFn = Apollo.MutationFunction<BedongBlockUserMutation, BedongBlockUserMutationVariables>;

/**
 * __useBedongBlockUserMutation__
 *
 * To run a mutation, you first call `useBedongBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBedongBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bedongBlockUserMutation, { data, loading, error }] = useBedongBlockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBedongBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BedongBlockUserMutation, BedongBlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BedongBlockUserMutation, BedongBlockUserMutationVariables>(BedongBlockUserDocument, options);
      }
export type BedongBlockUserMutationHookResult = ReturnType<typeof useBedongBlockUserMutation>;
export type BedongBlockUserMutationResult = Apollo.MutationResult<BedongBlockUserMutation>;
export type BedongBlockUserMutationOptions = Apollo.BaseMutationOptions<BedongBlockUserMutation, BedongBlockUserMutationVariables>;
export const ShowUserBlackListDocument = gql`
    query showUserBlackList($input: ShowUserBlackListInput!) {
  showUserBlackList(input: $input) {
    data {
      id
      email
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useShowUserBlackListQuery__
 *
 * To run a query within a React component, call `useShowUserBlackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowUserBlackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowUserBlackListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShowUserBlackListQuery(baseOptions: Apollo.QueryHookOptions<ShowUserBlackListQuery, ShowUserBlackListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowUserBlackListQuery, ShowUserBlackListQueryVariables>(ShowUserBlackListDocument, options);
      }
export function useShowUserBlackListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowUserBlackListQuery, ShowUserBlackListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowUserBlackListQuery, ShowUserBlackListQueryVariables>(ShowUserBlackListDocument, options);
        }
export type ShowUserBlackListQueryHookResult = ReturnType<typeof useShowUserBlackListQuery>;
export type ShowUserBlackListLazyQueryHookResult = ReturnType<typeof useShowUserBlackListLazyQuery>;
export type ShowUserBlackListQueryResult = Apollo.QueryResult<ShowUserBlackListQuery, ShowUserBlackListQueryVariables>;
export const AddBlackListEmailDocument = gql`
    mutation addBlackListEmail($input: AddBlackListEmailInput!) {
  addBlackListEmail(input: $input) {
    success
  }
}
    `;
export type AddBlackListEmailMutationFn = Apollo.MutationFunction<AddBlackListEmailMutation, AddBlackListEmailMutationVariables>;

/**
 * __useAddBlackListEmailMutation__
 *
 * To run a mutation, you first call `useAddBlackListEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBlackListEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBlackListEmailMutation, { data, loading, error }] = useAddBlackListEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBlackListEmailMutation(baseOptions?: Apollo.MutationHookOptions<AddBlackListEmailMutation, AddBlackListEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBlackListEmailMutation, AddBlackListEmailMutationVariables>(AddBlackListEmailDocument, options);
      }
export type AddBlackListEmailMutationHookResult = ReturnType<typeof useAddBlackListEmailMutation>;
export type AddBlackListEmailMutationResult = Apollo.MutationResult<AddBlackListEmailMutation>;
export type AddBlackListEmailMutationOptions = Apollo.BaseMutationOptions<AddBlackListEmailMutation, AddBlackListEmailMutationVariables>;
export const DeleteBlackListEmailDocument = gql`
    mutation deleteBlackListEmail($input: DeleteBlackListEmailInput!) {
  deleteBlackListEmail(input: $input) {
    success
  }
}
    `;
export type DeleteBlackListEmailMutationFn = Apollo.MutationFunction<DeleteBlackListEmailMutation, DeleteBlackListEmailMutationVariables>;

/**
 * __useDeleteBlackListEmailMutation__
 *
 * To run a mutation, you first call `useDeleteBlackListEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlackListEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlackListEmailMutation, { data, loading, error }] = useDeleteBlackListEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBlackListEmailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlackListEmailMutation, DeleteBlackListEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlackListEmailMutation, DeleteBlackListEmailMutationVariables>(DeleteBlackListEmailDocument, options);
      }
export type DeleteBlackListEmailMutationHookResult = ReturnType<typeof useDeleteBlackListEmailMutation>;
export type DeleteBlackListEmailMutationResult = Apollo.MutationResult<DeleteBlackListEmailMutation>;
export type DeleteBlackListEmailMutationOptions = Apollo.BaseMutationOptions<DeleteBlackListEmailMutation, DeleteBlackListEmailMutationVariables>;