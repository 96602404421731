import React from 'react';

const CommuList = React.lazy(() => import('pages/community/commu/list'));
const CommuNoticeList = React.lazy(() => import('pages/community/notice/list'));
const BedongReportsList = React.lazy(() => import('pages/community/bedongReports'));
const BedongCommentReportsList = React.lazy(() => import('pages/community/bedongCommentReports'));
const SelectedUsersList = React.lazy(() => import('components/commu/commu/list/SelectedUsersList'));
const CommuUserRepostsList = React.lazy(() => import('pages/community/commuUserReports'));

const CommuUserBlocksList = React.lazy(() => import('pages/community/commuUserBlock'));
const BedongAbuseList = React.lazy(() => import('pages/community/BedongAbuseList'));
const BedongCommentAbuseList = React.lazy(() => import('pages/community/BedongCommentAbuseList'));

export const commuPagesConfigs = [
  {
    path: '/commu/list',
    element: <CommuList />,
  },
  {
    path: '/commu/selected-users-list',
    element: <SelectedUsersList />,
  },
  {
    path: '/commu-notice/list',
    element: <CommuNoticeList />,
  },
  {
    path: '/bedong/report/list',
    element: <BedongReportsList />,
  },
  {
    path: '/bedong/report/list',
    element: <BedongReportsList />,
  },
  {
    path: '/bedong/comment/report/list',
    element: <BedongCommentReportsList />,
  },
  {
    path: '/commu-user-report/list',
    element: <CommuUserRepostsList />,
  },
  {
    path: '/commu-user-block/list',
    element: <CommuUserBlocksList />,
  },
  {
    path: '/bedong/abuse/list',
    element: <BedongAbuseList />,
  },
  {
    path: '/bedong/comment/abuse/list',
    element: <BedongCommentAbuseList />,
  },
];
