import { InMemoryCache, makeVar, gql } from '@apollo/client';
import { currentDataGridVariable } from './datagrid/grid';

// Local states
export const currentToken = makeVar<string | null>(null);

// Local queries
export const GET_CURRENT_TOKEN = gql`
  query {
    token @client
  }
`;

// Field policy of local states
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token() {
          return currentToken();
        },
        gridVariable() {
          return currentDataGridVariable();
        },
      },
    },
  },
  addTypename: true,
});
