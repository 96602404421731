import React, { ReactNode, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlGlobalProvider } from '../helper/Utils';
import AppLocale from 'shared/localization';
import { useLocaleContext } from '../AppContextProvider/LocaleContextProvide';
import { useSidebarActionsContext } from '@crema/utility/AppContextProvider/SidebarContextProvider';
import { ThemeMode } from 'shared/constants/AppEnums';
import {
  DarkSidebar,
  LightSidebar,
} from '@crema/utility/AppContextProvider/defaultConfig';

interface AppLocaleProviderProps {
  children: ReactNode;
}

const AppLocaleProvider: React.FC<AppLocaleProviderProps> = (props) => {
  const { updateSidebarColorSet } = useSidebarActionsContext();
  useEffect(() => {
    const themeMode = localStorage.getItem('themeMode');
    if (themeMode === ThemeMode.LIGHT) {
      updateSidebarColorSet({
        sidebarBgColor: LightSidebar.sidebarBgColor,
        sidebarTextColor: LightSidebar.sidebarTextColor,
        sidebarMenuSelectedBgColor: LightSidebar.sidebarMenuSelectedBgColor,
        sidebarMenuSelectedTextColor: LightSidebar.sidebarMenuSelectedTextColor,
        sidebarHeaderColor: LightSidebar.sidebarHeaderColor,
        mode: 'Light',
      });
    } else {
      updateSidebarColorSet({
        sidebarBgColor: DarkSidebar.sidebarBgColor,
        sidebarTextColor: DarkSidebar.sidebarTextColor,
        sidebarMenuSelectedBgColor: DarkSidebar.sidebarMenuSelectedBgColor,
        sidebarMenuSelectedTextColor: DarkSidebar.sidebarMenuSelectedTextColor,
        sidebarHeaderColor: DarkSidebar.sidebarHeaderColor,
        mode: 'Dark',
      });
    }
  }, [updateSidebarColorSet, localStorage]);

  const { locale } = useLocaleContext();
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <IntlGlobalProvider>{props.children}</IntlGlobalProvider>
    </IntlProvider>
  );
};

export default AppLocaleProvider;
