import React from 'react';
const PushList = React.lazy(() => import('pages/push/list/index'));
const RepeatPushList = React.lazy(() => import('pages/push/list/repeatIndex'));
const PushAdd = React.lazy(() => import('pages/push/add/index'));
const RepeatPushAdd = React.lazy(() => import('pages/push/add/repeatIndex'));
const PushEdit = React.lazy(() => import('pages/push/edit/index'));
const PushCopy = React.lazy(() => import('pages/push/copy/index'));
const RepeatPushEdit = React.lazy(() => import('pages/push/edit/repeatIndex'));
const RepeatPushCopy = React.lazy(() => import('pages/push/copy/repeatIndex'));

export const PushPagesConfigs = [
  {
    path: '/push',
    element: <PushList />,
  },

  {
    path: '/repeat/push',
    element: <RepeatPushList />,
  },
  {
    path: '/push/add',
    element: <PushAdd />,
  },

  {
    path: '/repeat/push/add',
    element: <RepeatPushAdd />,
  },

  {
    path: '/push/edit/:id',
    element: <PushEdit />,
  },

  {
    path: '/push/copy/:id',
    element: <PushCopy />,
  },

  {
    path: '/repeat/push/edit/:id',
    element: <RepeatPushEdit />,
  },

  {
    path: '/repeat/push/copy/:id',
    element: <RepeatPushCopy />,
  },
];
