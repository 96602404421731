import React from 'react';

const NoticeList = React.lazy(
  () => import('pages/notice/noticeList/noticeList'),
);
const NoticeAdd = React.lazy(() => import('pages/notice/noticeAdd/noticeAdd'));

const NoticeEdit = React.lazy(
  () => import('pages/notice/noticeEdit/noticeEdit'),
);

export const noticePagesConfigs = [
  {
    path: '/notice/list',
    element: <NoticeList />,
  },
  {
    path: '/notice/add',
    element: <NoticeAdd />,
  },
  {
    path: '/notice/edit/:id',
    element: <NoticeEdit />,
  },
];
