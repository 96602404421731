import React from 'react';
import { authRouteConfig } from './auth';
import { initialUrl } from 'shared/constants/AppConst';
import Error403 from './errorPages/Error403';
import { errorPagesConfigs } from './errorPages';
import { profilePage } from './profile';
import { noticePagesConfigs } from './notice';
import { authorPagesConfigs } from './author';
import { appContentsPagesConfigs } from './appContents';
import { settingsPagesConfigs } from 'pages/system';
import { commuPagesConfigs } from 'pages/community';
import { postsPagesConfigs } from 'pages/posts';
import { mainPagesConfigs } from 'pages/main';
import { CommercePagesConfigs } from 'pages/commerce';
import { PushPagesConfigs } from 'pages/push';
import { EventPagesConfigs } from 'pages/events';
import { imagesPagesConfigs } from 'pages/images';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...mainPagesConfigs,
    ...profilePage,
    ...noticePagesConfigs,
    ...authorPagesConfigs,
    ...appContentsPagesConfigs,
    ...settingsPagesConfigs,
    ...authorPagesConfigs,
    ...commuPagesConfigs,
    ...postsPagesConfigs,
    ...CommercePagesConfigs,
    ...PushPagesConfigs,
    ...EventPagesConfigs,
    ...imagesPagesConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
