import React from 'react';

const Dashboard = React.lazy(() => import('pages/main/dashboard/index'));

export const mainPagesConfigs = [
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
];
