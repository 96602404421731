import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Logo } from '../../../../../assets/icon/logo_billy.svg';

interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo
        style={{
          width: '100px', // 원하는 너비값
          height: '76px', // 원하는 높이값
        }}
      />
    </Box>
  );
};

export default AppLogo;
