import React from 'react';

const EventList = React.lazy(() => import('pages/events/list/index'));
const EventAdd = React.lazy(() => import('pages/events/add/index'));
const EventEdit = React.lazy(() => import('pages/events/edit/index'));
const EventCopy = React.lazy(() => import('pages/events/copy/index'));

const EventPopupList = React.lazy(() => import('./popup/list'));
const EventPopupAdd = React.lazy(() => import('./popup/add'));
const EventPopupEdit = React.lazy(() => import('./popup/edit'));
const EventPopupCopy = React.lazy(() => import('./popup/copy'));

const RandomCouponList = React.lazy(() => import('./RandomCoupon/RandomCouponList'));
const RandomCouponAdd = React.lazy(() => import('./RandomCoupon/RandomCouponAdd'));
const RandomCouponCopy = React.lazy(() => import('./RandomCoupon/RandomCouponCopy'));
const RandomCouponEdit = React.lazy(() => import('./RandomCoupon/RandomCouponEdit'));

export const EventPagesConfigs = [
  {
    path: '/events',
    element: <EventList />,
  },
  {
    path: '/event/add',
    element: <EventAdd />,
  },
  {
    path: '/event/edit/:id',
    element: <EventEdit />,
  },
  {
    path: '/event/copy/:id',
    element: <EventCopy />,
  },
  {
    path: '/event/popups',
    element: <EventPopupList />,
  },
  {
    path: '/event/popups/add',
    element: <EventPopupAdd />,
  },
  {
    path: '/event/popups/edit/:id',
    element: <EventPopupEdit />,
  },
  {
    path: '/event/popups/copy/:id',
    element: <EventPopupCopy />,
  },
  {
    path: '/event/random-coupon',
    element: <RandomCouponList />,
  },
  {
    path: '/event/random-coupon/add',
    element: <RandomCouponAdd />,
  },
  {
    path: '/event/random-coupon/copy/:id',
    element: <RandomCouponCopy />,
  },
  {
    path: '/event/random-coupon/edit/:id',
    element: <RandomCouponEdit />,
  },
];
