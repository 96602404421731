import React from 'react';
import List from '@mui/material/List';

import { RouterConfigData } from '../../../../../pages/routesConfig';
import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import { filterRoutesByRole } from 'common/script';

const VerticalNav = () => {
  const currentRoleValue = localStorage.getItem('role') ? Number(localStorage.getItem('role')) : undefined;
  const currentOptionsValue = localStorage.getItem('options');
  const routesToShow = filterRoutesByRole(currentRoleValue, currentOptionsValue);

  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
      }}
      component="div"
    >
      {routesToShow.map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <NavVerticalGroup item={item} level={0} />}
          {item.type === 'collapse' && <VerticalCollapse item={item} level={0} />}
          {item.type === 'item' && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
