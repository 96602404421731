import routesConfig, { RouterConfigData } from 'pages/routesConfig';
import dayjs, { Dayjs } from 'dayjs';

export const isEmpty = (value: any): boolean => {
  if (value === null) return true;
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length < 1) return true;
  if (
    typeof value === 'object' &&
    value.constructor.name === 'Object' &&
    Object.keys(value).length < 1
  )
    return true;
  if (
    typeof value === 'object' &&
    value.constructor.name === 'String' &&
    Object.keys(value).length < 1
  )
    return true;
  return false;
};

/* 존재여부 체크 */
export const isNotEmpty = (value: any): boolean => !isEmpty(value);

export const perPage = 100;

//포탈 설정을 DB질의에서 변경 - 2개밖에 안쓰고 있어서 불필요한 DB질의 제거
export const postTypes = [
  { label: '커머스-임신', id: 51 },
  { label: '커머스-육아', id: 52 },
  { label: '커머스-임신준비', id: 53 },
];

export const permitTypes = [
  { label: '어드민', id: 7 },
  { label: '외주작가', id: 6 },
];

export const getImageAddress = (data: string) => {
  const regex = /<img[^>]+src=[\"']?([^>\"']+)[\"']?[^>]*>/g;
  let extractUrl = [];
  const returnData = [];
  while ((extractUrl = regex.exec(data))) {
    returnData.push(extractUrl[1]);
  }
  return returnData;
};

export const getFroalaImagesByHtml = async (html: string) => {
  const urls = getImageAddress(html);

  const froalaImages = [];

  for (let url of urls) {
    let type = undefined;
    url = url
      .toString()
      .replaceAll(process.env.REACT_APP_AWS_S3_CDN_URL, process.env.REACT_APP_AWS_S3_OLD_URL);
    const response = await fetch(url, { cache: 'no-cache' });
    const blob = await response.blob();

    if (url.startsWith(process.env.REACT_APP_AWS_S3_OLD_URL)) {
      type = 'image/' + url.split('.').pop();
    } else {
      type = blob.type;
    }

    froalaImages.push(
      new File([blob], url, {
        type: type,
        lastModified: Date.now(),
      }),
    );
  }

  return froalaImages;
};

export const languages = [
  {
    id: 1,
    code: 'ko',
  },
  {
    id: 2,
    code: 'vi',
  },
  {
    id: 3,
    code: 'en',
  },
  {
    id: 4,
    code: 'th',
  },
  {
    id: 5,
    code: 'id',
  },
  {
    id: 6,
    code: 'ja',
  },
];

export const selectedLocale = (): number => {
  const { locale } = localStorage.getItem('language')
    ? JSON.parse(localStorage.getItem('language'))
    : {
        locale: '1',
      };
  return Number(locale);
};

export const localBookingTime = (time: string, day?: string): Dayjs => {
  const todayDate: string = day ? day : dayjs().format('YYYY-MM-DD');
  const dateTime: string = todayDate + ' ' + time;
  // Now convert this dateTime string into local time assuming the local timezone is Asia/Seoul
  if (selectedLocale() === 1) {
    return dayjs(dateTime).tz('Asia/Seoul'); //.format('HH:mm:ss');
  }
  if (selectedLocale() === 2) {
    return dayjs(dateTime).tz('Asia/Ho_Chi_Minh'); //.format('HH:mm:ss');
  }
  if (selectedLocale() === 4) {
    return dayjs(dateTime).tz('Asia/Bangkok');
  }
  if (selectedLocale() === 5) {
    return dayjs(dateTime).tz('Asia/Jakarta'); //.format('HH:mm:ss');
  }
  if (selectedLocale() === 6) {
    return dayjs(dateTime).tz('Asia/Tokyo'); //.format('HH:mm:ss');
  }

  return dayjs(dateTime);
};

export const convertLanguage = (value: number | string) => {
  let result = undefined;
  if (typeof value === 'number') {
    result = languages.find((obj) => obj.id === value)?.code;
  } else if (typeof value === 'string') {
    result = languages.find((obj) => obj.code === value)?.id;
  }
  return result;
};

export const languageCoderArray = (value: number | string) => {
  const result = languages.find((obj) => obj.code === value);
  return result;
};

// export const convertToCronExpression = (days: string[], date: Date) => {
//   // Date 객체에서 시간과 분을 추출합니다.
//   const hour = date.getHours();
//   const minute = date.getMinutes();

//   // 시간과 분을 문자열로 변환합니다.
//   const hourString = hour.toString();
//   const minuteString = minute.toString();

//   // 요일을 cron 표현식에서 사용하는 문자열로 변환합니다.

//   if (Array.isArray(days) && days.length > 0) {
//     const daysString = days.join(',');
//     // cron 표현식을 생성합니다.
//     const cronExpression = `${minuteString} ${hourString} * * ${daysString}`;

//     return cronExpression;
//   } else {
//     const cronExpression = `${minuteString} ${hourString} * * `;

//     return cronExpression;
//   }
// };

export const convertToCronExpression = (days: string[], date: Date, dateStr?: string) => {
  // 시간과 분을 문자열로 변환합니다.
  const hour = date.getHours();
  const minute = date.getMinutes();
  const hourString = hour.toString();
  const minuteString = minute.toString();

  if (dateStr) {
    // 날짜 문자열에서 일과 월을 추출합니다.
    const [, month, day] = dateStr.split('-').map((str) => parseInt(str));

    return `${minuteString} ${hourString} ${day} ${month} *`;
  } else if (Array.isArray(days) && days.length > 0) {
    const daysString = days.join(',');
    // cron 표현식을 생성합니다.
    return `${minuteString} ${hourString} * * ${daysString}`;
  } else {
    return `${minuteString} ${hourString} * * *`;
  }
};

const convertStringNull = (value: string | null | undefined): string | null => {
  if (value === 'null') {
    return null;
  }
  return value;
};

export const filterRoutesByRole = (role: number, options?: string): RouterConfigData[] => {
  if (role === 6 && convertStringNull(options)) {
    return routesConfig.filter((route) => route.id === 'posts' || route.id === 'commu');
  }

  if (role === 6) {
    return routesConfig.filter((route) => route.id === 'posts');
  }
  return routesConfig;
};

export const correctAllUrls = (input) => {
  // 모든 href 속성값을 찾기 위해 정규 표현식을 사용
  const urls = input.match(/href="([^"]*)"/g);

  // 각 링크에 대해 수정 작업 수행
  if (urls) {
    urls.forEach((url) => {
      const originalUrl = url.match(/href="([^"]*)"/)[1];
      if (originalUrl.includes('deeplink.page')) {
        const indexOfDoubleSlash = originalUrl.indexOf('//');
        if (indexOfDoubleSlash !== -1) {
          let urlPart = originalUrl.substring(indexOfDoubleSlash + 2);
          if (!urlPart.startsWith('billyapplication://')) {
            urlPart = 'billyapplication://' + urlPart;
          } else {
            urlPart = 'billyapplication://' + urlPart.substring(21);
          }
          input = input.replace(originalUrl, urlPart);
        }
      }
    });
  }

  return input;
};
