import React from 'react';

const ImageUploadPage = React.lazy(() => import('pages/images/upload/index'));

export const imagesPagesConfigs = [
  {
    path: '/images/upload',
    element: <ImageUploadPage />,
  },
];
