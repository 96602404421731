import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { cache, currentToken } from './cache';

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_URL}/graphql`,
});

// Authenticate using HTTP header
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  const language = localStorage.getItem('language')
    ? JSON.parse(localStorage.getItem('language'))
    : {
        languageId: 'korean',
        locale: '1',
        name: 'Korean',
      };
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      locale: Number(language.locale),
    },
  };
});

const unauthorizedLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    response.errors?.map((error) => {
      if (error.extensions.code === 401000) {
        localStorage.removeItem('token');
        currentToken(null);
      }
    });
    return response;
  });
});

export const client = new ApolloClient({
  link: unauthorizedLink.concat(authLink.concat(httpLink)),
  cache: cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});
