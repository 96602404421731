import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import clsx from 'clsx';
import { CustomizerItemWrapper, StyledToggleButton } from '../index.style';
import IntlMessages from '../../../utility/IntlMessages';
import { ThemeMode } from '../../../../shared/constants/AppEnums';
import {
  useThemeActionsContext,
  useThemeContext,
} from '../../../utility/AppContextProvider/ThemeContextProvider';
import { useSidebarActionsContext } from '../../../utility/AppContextProvider/SidebarContextProvider';
import {
  DarkSidebar,
  LightSidebar,
} from '../../../utility/AppContextProvider/defaultConfig';
import { th } from 'date-fns/locale';

const ThemeModes = () => {
  const { updateThemeMode } = useThemeActionsContext();
  const { updateSidebarColorSet } = useSidebarActionsContext();
  //const { themeMode, theme } = useThemeContext();
  const [themeMode, setThemeMode] = React.useState(
    localStorage.getItem('themeMode') || ThemeMode.LIGHT,
  );
  const { theme } = useThemeContext();

  const onModeChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, themeMode: ThemeMode) => {
      if (themeMode) {
        updateThemeMode(themeMode);
        if (themeMode === ThemeMode.LIGHT) {
          localStorage.setItem('themeMode', ThemeMode.LIGHT);
          setThemeMode(ThemeMode.LIGHT);
          updateSidebarColorSet({
            sidebarBgColor: LightSidebar.sidebarBgColor,
            sidebarTextColor: LightSidebar.sidebarTextColor,
            sidebarMenuSelectedBgColor: LightSidebar.sidebarMenuSelectedBgColor,
            sidebarMenuSelectedTextColor:
              LightSidebar.sidebarMenuSelectedTextColor,
            sidebarHeaderColor: LightSidebar.sidebarHeaderColor,
            mode: 'Light',
          });
        } else {
          localStorage.setItem('themeMode', ThemeMode.DARK);
          setThemeMode(ThemeMode.DARK);
          updateSidebarColorSet({
            sidebarBgColor: DarkSidebar.sidebarBgColor,
            sidebarTextColor: DarkSidebar.sidebarTextColor,
            sidebarMenuSelectedBgColor: DarkSidebar.sidebarMenuSelectedBgColor,
            sidebarMenuSelectedTextColor:
              DarkSidebar.sidebarMenuSelectedTextColor,
            sidebarHeaderColor: DarkSidebar.sidebarHeaderColor,
            mode: 'Dark',
          });
        }
      }
    },
    [themeMode],
  );

  return (
    <CustomizerItemWrapper>
      <Box component="h4" sx={{ mb: 2 }}>
        <IntlMessages id="customizer.themeMode" />
      </Box>
      <ToggleButtonGroup
        value={themeMode}
        exclusive
        onChange={onModeChange}
        aria-label="text alignment"
      >
        <StyledToggleButton
          value={ThemeMode.LIGHT}
          className={clsx({
            active: themeMode === ThemeMode.LIGHT,
          })}
          aria-label="left aligned"
        >
          <IntlMessages id="customizer.light" />
        </StyledToggleButton>

        <StyledToggleButton
          value={ThemeMode.DARK}
          className={clsx({
            active:
              themeMode === ThemeMode.DARK ||
              theme.palette.type === ThemeMode.DARK,
          })}
          aria-label="centered"
        >
          <IntlMessages id="customizer.dark" />
        </StyledToggleButton>
      </ToggleButtonGroup>
    </CustomizerItemWrapper>
  );
};

export default ThemeModes;
