export interface LanguageProps {
  languageId: string;
  locale: string;
  name: string;
}

const languageData: LanguageProps[] = [
  {
    languageId: 'korean',
    locale: '1',
    name: 'Korean',
  },
  {
    languageId: 'vietnamese',
    locale: '2',
    name: 'Vietnamese',
  },
  {
    languageId: 'english',
    locale: '3',
    name: 'English',
  },
  {
    languageId: 'thai',
    locale: '4',
    name: 'Thai',
  },
  {
    languageId: 'indonesian',
    locale: '5',
    name: 'Indonesian',
  },
  {
    languageId: 'japanese',
    locale: '6',
    name: 'Japanese',
  },

  // {
  //   languageId: 'test',
  //   locale: '99',
  //   name: 'TestLanguage',
  // },
];

export default languageData;
