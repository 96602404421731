import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EventIcon from '@mui/icons-material/Event';
import ImageIcon from '@mui/icons-material/Image';
import LockIcon from '@mui/icons-material/Lock';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RedeemIcon from '@mui/icons-material/Redeem';
import { ReactNode } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiAlignLeft } from 'react-icons/bi';
import { RoutePermittedRole } from '../shared/constants/AppConst';
export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: 'item' | 'group' | 'collapse' | 'divider';
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routes: RouterConfigData[] = [
  {
    id: 'main',
    title: 'Home',
    messageId: 'Home',
    type: 'item',
    icon: <BiAlignLeft />,
    url: '/dashboard',
  },
  {
    id: 'posts',
    title: '콘텐츠 관리',
    messageId: '콘텐츠 관리',
    type: 'collapse',
    children: [
      {
        id: 'postsList',
        title: '콘텐츠 리스트',
        messageId: '콘텐츠 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/posts/list',
      },
      {
        id: 'apppostsAdd',
        title: '콘텐츠 추가',
        messageId: '콘텐츠 추가',
        type: 'item',
        icon: <AddIcon />,
        url: '/post/create',
      },
    ],
  },
  {
    id: 'images',
    title: '이미지 관리',
    messageId: '이미지 관리',
    type: 'collapse',
    children: [
      {
        id: 'imageUpload',
        title: '이미지 등록 및 링크 얻기',
        messageId: '이미지 등록 및 링크 얻기',
        type: 'item',
        icon: <AddPhotoAlternateIcon />,
        url: '/images/upload',
      },
    ],
  },
  {
    id: 'Events',
    title: '이벤트 관리',
    messageId: '이벤트 관리',
    type: 'collapse',
    children: [
      {
        id: 'eventsList',
        title: '이벤트 리스트',
        messageId: '이벤트 리스트',
        type: 'item',
        icon: <EventIcon />,
        url: '/events',
      },
      {
        id: 'eventPopup',
        title: '공지/이벤트 팝업',
        messageId: '공지/이벤트 팝업',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/event/popups',
      },
      {
        id: 'randomCoupon',
        title: '랜덤 쿠폰 이벤트',
        messageId: '랜덤 쿠폰 이벤트',
        type: 'item',
        icon: <RedeemIcon fontSize='small' />,
        url: '/event/random-coupon',
      },
    ],
  },
  {
    id: 'appContents',
    title: '앱콘텐츠 관리',
    messageId: '앱콘텐츠 관리',
    type: 'collapse',
    children: [
      {
        id: 'appContentsList',
        title: '앱콘텐츠 리스트',
        messageId: '앱콘텐츠 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/app-contents/list',
      },
      {
        id: 'appContentsAdd',
        title: '앱콘텐츠 추가',
        messageId: '앱콘텐츠 추가',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/app-contents/add',
      },
      {
        id: 'splash',
        title: '스플래시',
        messageId: '스플래시',
        type: 'item',
        icon: <ImageIcon />,
        url: '/splash',
      },
    ],
  },
  {
    id: 'notice',
    title: '공지사항',
    messageId: '공지사항',
    type: 'collapse',
    children: [
      {
        id: 'noticeList',
        title: '공지사항 리스트',
        messageId: '공지사항 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/notice/list',
      },
      {
        id: 'noticeAdd',
        title: '공지사항 추가',
        messageId: '공지사항 추가',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/notice/add',
      },
    ],
  },
  {
    id: 'commu',
    title: '커뮤니티',
    messageId: '커뮤니티',
    type: 'collapse',
    children: [
      {
        id: 'commuList',
        title: '커뮤니티 관리',
        messageId: '커뮤니티 관리',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/commu/list',
      },
      {
        id: 'noticeList',
        title: '공지사항 리스트',
        messageId: '공지사항 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/commu-notice/list',
      },
      {
        id: 'bedongReportsList',
        title: '커뮤니티 게시글 신고 리스트',
        messageId: '커뮤니티 게시글 신고 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/bedong/report/list',
      },
      {
        id: 'bedongCommentReportsList',
        title: '커뮤니티 댓글 신고 리스트',
        messageId: '커뮤니티 댓글 신고 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/bedong/comment/report/list',
      },
      {
        id: 'commuUserReport',
        title: '유저 신고 리스트',
        messageId: '유저 신고 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/commu-user-report/list',
      },
      {
        id: 'commuUserBlock',
        title: '유저 차단 리스트',
        messageId: '유저 차단 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/commu-user-block/list',
      },
      {
        id: 'bedongAIBlockList',
        title: 'AI 차단글 리스트',
        messageId: 'AI 차단글 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/bedong/abuse/list',
      },
      {
        id: 'bedongCommentAIBlockList',
        title: 'AI 차단 댓글 리스트',
        messageId: 'AI 차단 댓글 리스트',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/bedong/comment/abuse/list',
      },
    ],
  },
  {
    id: 'system',
    title: '사용자 관리',
    messageId: '사용자 관리',
    type: 'collapse',
    children: [
      {
        id: 'users',
        title: '유저',
        messageId: '유저',
        type: 'item',
        icon: <PersonOutlineIcon />,
        url: '/users',
      },
      {
        id: 'admin',
        title: '블랙리스트',
        messageId: '블랙리스트',
        type: 'item',
        icon: <PersonOffIcon />,
        url: '/blacklist',
      },
      {
        id: 'authorsList',
        title: '작가',
        messageId: '작가',
        type: 'item',
        icon: <AiOutlineEdit />,
        url: '/author/list',
      },
      {
        id: 'admin',
        title: '어드민',
        messageId: '어드민',
        type: 'item',
        icon: <LockIcon />,
        url: '/admin',
      },
    ],
  },
  {
    id: 'commerce',
    title: '커머스 관리',
    messageId: '커머스 관리',
    type: 'collapse',
    children: [
      {
        id: 'generate',
        title: 'generate',
        messageId: '상세페이지 Generate',
        type: 'item',
        icon: <PersonOutlineIcon />,
        url: '/commerce/item-detail-generate',
      },
      {
        id: '쿠폰 발급',
        title: '쿠폰 발급',
        messageId: '쿠폰 발급',
        type: 'item',
        icon: <AddIcon />,
        url: '/commerce/coupon/create',
      },
    ],
  },

  {
    id: 'push',
    title: '푸시 관리',
    messageId: '푸시 관리',
    type: 'collapse',
    children: [
      {
        id: '푸시리스트',
        title: '푸시리스트',
        messageId: '푸시리스트',
        type: 'item',
        icon: <MarkUnreadChatAltIcon />,
        url: '/push',
      },

      {
        id: '반복 푸시리스트',
        title: '반복 푸시리스트',
        messageId: '반복 푸시리스트',
        type: 'item',
        icon: <MarkUnreadChatAltIcon />,
        url: '/repeat/push',
      },
    ],
  },
];

export default routes;
