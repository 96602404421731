import React from 'react';
const UsersList = React.lazy(() => import('pages/system/users/index'));
const BlackList = React.lazy(() => import('pages/system/blackList/list'));
const AdminList = React.lazy(() => import('pages/system/admin/index'));

export const settingsPagesConfigs = [
  {
    path: '/users',
    element: <UsersList />,
  },
  {
    path: '/blackList',
    element: <BlackList />,
  },
  {
    path: '/admin',
    element: <AdminList />,
  },
];
