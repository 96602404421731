import React from 'react';

const PostsList = React.lazy(() => import('pages/posts/list/index'));

const PostsCreate = React.lazy(() => import('pages/posts/create'));

const PostsEdit = React.lazy(() => import('pages/posts/edit'));

export const postsPagesConfigs = [
  {
    path: '/posts/list',
    element: <PostsList />,
  },
  {
    path: '/post/create',
    element: <PostsCreate />,
  },
  {
    path: '/post/edit/:id',
    element: <PostsEdit />,
  },
];
