import React from 'react';

const ItemDetailGenerate = React.lazy(() => import('pages/commerce/itemDetail/index'));
const CouponIssuer = React.lazy(() => import('pages/commerce/CouponIssuer'));

export const CommercePagesConfigs = [
  {
    path: '/commerce/item-detail-generate',
    element: <ItemDetailGenerate />,
  },
  {
    path: '/commerce/coupon/create',
    element: <CouponIssuer />,
  },
];
