import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import jwtAxios, { setAuthToken } from "./index";
import { AuthUser } from '../../../../types/models/AuthUser';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { useLoginMutation, useMeQuery, useRegisterMutation } from 'generated/graphql';
import { currentToken, GET_CURRENT_TOKEN } from 'apollo/cache';
import { useQuery } from '@apollo/client';

interface JWTAuthContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthProviderProps {
  children: ReactNode;
}

const JWTAuthProvider: React.FC<JWTAuthProviderProps> = ({ children }) => {
  const { data: meData, refetch } = useMeQuery();

  const [jwtData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();

  const {
    data: { token: curToken },
  } = useQuery(GET_CURRENT_TOKEN);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    currentToken(token);
    const getAuthUser = () => {
      if (!token && !curToken) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
    };
    getAuthUser();
    refetch();
    if (token && meData) {
      localStorage.setItem('role', String(meData.me.role));
      localStorage.setItem('options', meData.me.options);

      setJWTAuthData({
        user: { ...meData.me, role: String(meData.me.role) },
        isAuthenticated: true,
        isLoading: false,
      });
    }
  }, [curToken, meData]);

  const signInUser = async ({ email, password }: { email: string; password: string }) => {
    dispatch(fetchStart());
    try {
      const result = await login({
        variables: {
          loginInput: {
            email: email,
            password: password,
          },
        },
      });
      const token = result.data.login.accessToken;

      localStorage.setItem('role', String(result.data.login.user.role));

      localStorage.setItem('options', result.data.login.user.options);

      localStorage.setItem('token', token);

      currentToken(token);
      setJWTAuthData({
        user: { ...result.data.login.user, role: String(result.data.login.user.role) },
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jwtData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError('Something went wrong'));
    }
  };

  const signUpUser = async ({ name, email, password }: { name: string; email: string; password: string }) => {
    dispatch(fetchStart());
    try {
      const result = await register({
        variables: {
          registerInput: {
            email,
            password,
            password_confirmation: password,
            username: name,
          },
        },
      });
      localStorage.setItem('token', result.data.register.accessToken);
      currentToken(result.data.register.accessToken);
      // setAuthToken(result.data.register.accessToken);
      setJWTAuthData({
        user: { ...result.data.register.user, role: String(result.data.register.user.role) },
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jwtData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError('Something went wrong'));
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('options');

    currentToken(null);
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthProvider;
