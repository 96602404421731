import React from 'react';
import * as ReactDOM from 'react-dom/client';
import '@crema/utility/custom.scss';
import '@crema/services';

import App from './App';
import reportWebVitals from './reportWebVitals';
/**
 * dayjs 설정
 */
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear'; // 윤년 판단 플러그인
import 'dayjs/locale/ko'; // 한국어 가져오기
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(isLeapYear); // 플러그인 등록
dayjs.extend(utc);
dayjs.extend(timezone);
//dayjs.locale('ko'); // 언어 등록

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
reportWebVitals();
